import React from 'react';
import {FormGroup as FormGroupBase} from 'reactstrap';

const FormGroup = ({
    check,
    className,
    children
}) => (
    <FormGroupBase check={check} className={className}>
        {children}
    </FormGroupBase>
)

export default FormGroup;