export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('viewberDashboardState');

        if (serializedState === null) {
            return undefined;
        }

        return JSON.parse(serializedState);
    } catch(err) {
        return undefined;
    }
}

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(sanitizeState(state));
        localStorage.setItem('viewberDashboardState', serializedState);
    } catch(err) {}
}

export const sanitizeState = (state) => {
    const { token } = state.auth;
    // const {
    //     resources,
    // } = state.viewberBillingTypes;
    return {
        auth: { token },
        // viewberBillingTypes: {
        //     resources
        // }
    }
};
