import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {isNil, isString} from 'lodash';
import queryString, { parse } from 'query-string';
import { Redirect } from 'react-router-dom';
import { signInWithReapit } from '../../redux/auth/actions';
import { Container, Row, Col, Card, CardBody} from 'reactstrap';
import loginLogo from '../../assets/img/brand/viewber_logo.png';

const SignInReapit = ({match, location, history}) => {
    const dispatch = useDispatch();
    const {token, logging_in, error} = useSelector(state => state.auth)

    const parsed = queryString.parse(location.search);

    React.useEffect(() => {
        if (isString(parsed.code) && parsed.code !== '') {
            dispatch(signInWithReapit({
                code: parsed.code
            }));
        }
    }, []);

    if (token) {
        return <Redirect to='/' />;
    }

    if (isNil(parsed.code) || !isString(parsed.code) || parsed.code === '' || (error !== null && typeof error !== 'undefined')) {
        return <Redirect to='login?signInWithReapitFailed=1' />
    }

    return (
        <div className="app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col md="6">
                        <Card className="p-2">
                            <CardBody>
                                <img
                                    src={loginLogo}
                                    alt="Viewber logo"
                                    className="w-50 d-block mx-auto"
                                />
                                {logging_in && (
                                    <div className='mt-3 text-center'>
                                        <i className='fa fa-spinner fa-2x fa-spin'/>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SignInReapit;