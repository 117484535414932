import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../auth/actions';

const reducer = (actions) => (state, action) => {
    switch(action.type) {
        case LOGIN_SUCCESS:
        case LOGOUT_SUCCESS:
            return {
                ...state,
                error: null,
            };
        case actions.FIND_RESOURCE_REQUEST:
            return {
                ...state,
                error: null,
                resource: null,
            };
        case actions.FIND_RESOURCE_SUCCESS:
            return {
                ...state,
                error: null,
                resource: action.payload.resource,
            };
        case actions.FIND_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
                resource: null,
            };
        case actions.GET_ALL_RESOURCES_REQUEST: {
            return {
                ...state,
                error: null,
                fetching_resources: true,
                resources: [],
                total: 0,
            };
        }
        case actions.GET_ALL_RESOURCES_SUCCESS: {
            const { resources } = action.payload;

            return {
                ...state,
                resources,
                error: null,
                fetching_resources: false,
                total: resources.length,
            };
        }
        case actions.GET_ALL_RESOURCES_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_resources: false,
                total: 0,
            };
        case actions.SET_ERROR:
            return {
                ...state,
                error: action.payload.data.error,
            };
        case actions.SET_RELATIONSHIP_ITEM_RESOURCE: {
            const { resource } = state;
            const { item, relationshipName } = action.payload.data;

            if(!item || !relationshipName) {
                return state;
            }

            if(!resource || !resource[relationshipName] || typeof resource[relationshipName].length === 'undefined') {
                return state;
            }

            return {
                ...state,
                resource: {
                    ...resource,
                    [relationshipName]: resource[relationshipName].concat([item]),
                },
            };
        }
        case actions.SET_RESOURCE: {
            return {
                ...state,
                // error: null,
                destroyed: false,
                resource: action.payload.data.resource,
            };
        }
        case actions.UNSET_RELATIONSHIP_ITEM_RESOURCE: {
            const { resource } = state;
            const { id, relationshipName } = action.payload.data;

            if(!id || !relationshipName) {
                return state;
            }

            if(!resource || !resource[relationshipName] || !resource[relationshipName].length) {
                return state;
            }

            return {
                ...state,
                resource: {
                    ...resource,
                    [relationshipName]: resource[relationshipName].filter(item => item.id !== id),
                },
            };
        }
        default:
            return state;
    }
};

export default reducer;
