import React, { Suspense } from 'react';
import SpinnerRow from '../components/SpinnerRow';

const WaitingComponent = ({
    Component,
    query,
    ...props
}) => (
    <Suspense fallback={<SpinnerRow />}>
        <Component {...props} query={query} />
    </Suspense>
);

export default WaitingComponent;
