import React from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react';

const FallbackUI = ({ error }) => {
    const [redirectHome, setRedirectHome] = React.useState(false);

    if (redirectHome) {
        return <Redirect to="/" />;
    }

    return (
        <div className="app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col md="6">
                        <span className="clearfix">
                            <h1 className="float-left display-3 mr-4">500</h1>
                            <h4 className="pt-3">Houston, we have a problem!</h4>
                            <p className="text-muted float-left">The page you are looking for is temporarily unavailable.</p>
                        </span>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="6">
                        <Button className="btn-home btn-brand" onClick={() => setRedirectHome(true)}>
                            <i className="fa fa-home"></i>
                            <span>Home</span>
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

const ErrorBoundary = ({ children }) => 
    <RollbarErrorBoundary
        fallbackUI={FallbackUI}
        children={children}
    />
;

export default ErrorBoundary;
