import React from 'react';
import FormGroup from '../Form/FormGroup';
import FormikTextArea from './FormikTextArea';

const FormikFormGroupTextArea = props => {
    return (
        <FormGroup>
            <FormikTextArea {...props} />
        </FormGroup>
    )
}

FormikFormGroupTextArea.propTypes = FormikTextArea.propTypes;

export default FormikFormGroupTextArea;