import React from 'react';
import cuid from 'cuid';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import findIndex from 'lodash/findIndex';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { paginate } from '../../../api/properties';
import { FormGroup, RadioInput, TextInput } from '../../Form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const ChoosePropertyModal = ({
    isOpen,
    toggle,
    selectedViewingLength,
    onBookNow,
    onCreateNewProperty,
}) => {
    const currentRequestId = React.useRef();
    const token = useSelector(store => store.auth.token);
    const history = useHistory();
    const [query, setQuery] = React.useState('');
    const [properties, setProperties] = React.useState([]);
    const [selectedProperty, setSelectedProperty] = React.useState(undefined);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(undefined);
    const [hasPerformedFirstLoad, setHasPerformedFirstLoad] = React.useState(false);

    const handleSearchInputChange = value => {
        setQuery(value);
        debouncedSearchProperties({query: value}, properties => {
            // Clear the selected property if it's not in the new dataset.
            if (selectedProperty && findIndex(properties, ['id', selectedProperty.id]) === -1) {
                setSelectedProperty(undefined);
            }
        });
    }

    const searchProperties = (params = {}, onSuccess) => {
        const requestId = currentRequestId.current = cuid();
        setLoading(true);
        setError(undefined);

        paginate({
            token,
            q: params.query || query,
            pageSize: 8,
            sort_name: 'created',
            sort_direction: 'desc'
        }).then(response => {
            if (requestId !== currentRequestId.current) {
                return;
            }
            setLoading(false);
            setProperties(response.data.data);
            if (onSuccess) {
                // We need the onSuccess to allow the user
                // to perform post request updates because
                // this function scope does not have access
                // to the current state values because it is
                // mostly called from a useCallback().
                onSuccess(response.data.data);
            }
        }).catch(err => {
            setLoading(false);
            setError(get(err, 'response.data.message', 'Server Error'));
        });
    }

    // Any time one of the dependencies changes, it remakes
    // the debounce function which breaks the deboucing and
    // sends multiple requests, so we limit the dependencies
    // passed to this useCallback() and use the onSuccess()
    // parameter of searchProperties() to run post request
    // code with the current state values (using any state
    // values within the debounced request function will
    // refer to stale state because the function is persistent
    // due to useCallback()).
    const debouncedSearchProperties = React.useCallback(
        debounce(searchProperties, 300),
        [token]
    );

    const handleCreateNewPropertyClick = () => {
        history.push(
            !selectedViewingLength
                ? '/properties/create?redirectToBookingFormOnCreate=1'
                : `/properties/create?redirectToBookingFormOnCreate=1&viewingLength=${selectedViewingLength.id}`
        );
        if (onCreateNewProperty) {
            onCreateNewProperty();
        }
    }

    const handleBookNowButtonClick = () => {
        if (onBookNow && selectedProperty) {
            onBookNow(selectedProperty);
        }
    }

    React.useEffect(() => {
        if (isOpen && !hasPerformedFirstLoad) {
            searchProperties({}, properties => {
                setHasPerformedFirstLoad(true);
            });
        }
    }, [isOpen]);

    const searchInput = (
        <React.Fragment>
            <FormGroup className='mb-2'>
                <TextInput
                    id='price-list-chose-property-modal-search-input'
                    value={query}
                    placeholder='Search'
                    onChange={e => handleSearchInputChange(e.target.value)}
                    // Disable suggestions popup.
                    aria-autocomplete='both'
                    aria-haspopup='false'
                />
            </FormGroup>
        </React.Fragment>
    )

    let modalBody;
    let modalBodyClassName;

    if (loading) {
        modalBody = (
            <React.Fragment>
                {!!hasPerformedFirstLoad && searchInput}
                <div className='text-center'>
                    <i className='fa fa-spinner fa-spin fa-2x' />
                </div>
            </React.Fragment>
        )
    } else if (error) {
        modalBody = (
            <React.Fragment>
                <div className='text-center'>
                    <p>{error}</p>
                    {/*
                        Use handleSearchInputChange() to prevent duplicating the
                        onSuccess() logic contained within handleSearchInputChange().
                    */}
                    <Button
                        type='button'
                        color='primary'
                        disabled={loading}
                        onClick={() => handleSearchInputChange(query)}
                    >
                        Retry
                    </Button>
                </div>
            </React.Fragment>
        )
    } else {
        modalBodyClassName = 'pb-0';
        modalBody = (
            <React.Fragment>
                {searchInput}
                {properties.length === 0
                    ? (
                        <div className='text-center p-3'>
                            <p className='mb-2'>
                                {query.trim() === ''
                                    ? 'No results'
                                    : `No results for: ${query}`
                                }
                            </p>
                            <Button
                                type="button"
                                color="link"
                                className='mr-1'
                                disabled={loading}
                                onClick={handleCreateNewPropertyClick}
                            >
                                Create a property
                            </Button>
                        </div>
                    ) : (
                        <ul className='list-group list-group-flush'>
                            {properties.map(property => {
                                return (
                                    <li
                                        key={property.id}
                                        className='list-group-item'
                                    >
                                        <RadioInput
                                            name='property'
                                            checked={!!selectedProperty && property.id === selectedProperty.id}
                                            onChange={() => setSelectedProperty(property)}
                                        >
                                            {property.full_address}
                                        </RadioInput>
                                    </li>
                                )
                            })}
                        </ul>
                    )
                }
            </React.Fragment>
        )
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
        >
            <ModalHeader toggle={toggle}>
                Select a property
            </ModalHeader>
            <ModalBody className={modalBodyClassName}>
                {modalBody}
            </ModalBody>
            <ModalFooter>
                <div className='d-flex w-100 justify-content-between'>
                    <div>
                        <Button
                            type="button"
                            color="light-blue"
                            className='mr-1'
                            disabled={loading}
                            onClick={handleCreateNewPropertyClick}
                        >
                            <span className='d-md-none'>
                                Create property
                            </span>
                            <span className='d-none d-md-block'>
                                <i className='fa fa-home mr-1' />
                                Create new property
                            </span>
                        </Button>
                    </div>
                    <div>
                        <Button
                            type="button"
                            color="primary"
                            className='mr-1'
                            disabled={loading || !selectedProperty}
                            onClick={handleBookNowButtonClick}
                        >
                            Book now
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
}

ChoosePropertyModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    selectedViewingLength: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }),
    onBookNow: PropTypes.func.isRequired,
    onCreateNewProperty: PropTypes.func,
}

export default ChoosePropertyModal;