import apiRequest from '../apiRequest';

export const checkDiscount = ({
    token,
    discountCode,
    propertyId,
    viewingLengthId,
    preferredDate,
}) =>
	apiRequest('/discounts/check', {
        method: 'POST',
        token,
        data: {
            discount_code: discountCode,
            properties_id: propertyId,
            viewing_length_id: viewingLengthId,
            preferred_date: preferredDate
        }
    });