import React from 'react';
import PropTypes from 'prop-types';
import {Table as BaseTable} from 'reactstrap';

const Table = ({responsive, hover, className, children}) => (
    <BaseTable
        responsive={responsive}
        hover={hover}
        className={className}
    >
        {children}
    </BaseTable>
)

Table.propTypes = {
    responsive: PropTypes.bool,
    hover: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node
}

export default Table;