import actions from './actions';
import baseReducer from '../base/reducer';

const initial_state = {
    error: null,
    fetching_resources: false,
    resources: [],
    total: 0,
};

const reducer = (state = initial_state, action) => {
    switch(action.type) {
        case actions.GET_POSTCODE_CLOSEST_KEYNEST_STORES_REQUEST: {
            return {
                ...state,
                error: null,
                fetching_resources: true,
                resources: [],
                total: 0,
            };
        }
        case actions.GET_POSTCODE_CLOSEST_KEYNEST_STORES_SUCCESS: {
            const { resources } = action.payload;

            return {
                ...state,
                resources,
                error: null,
                fetching_resources: false,
                total: resources.length,
            };
        }
        case actions.GET_POSTCODE_CLOSEST_KEYNEST_STORES_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_resources: false,
                total: 0,
            };
        case actions.GET_PROPERTY_CLOSEST_KEYNEST_STORES_REQUEST: {
            return {
                ...state,
                error: null,
                fetching_resources: true,
                resources: [],
                total: 0,
            };
        }
        case actions.GET_PROPERTY_CLOSEST_KEYNEST_STORES_SUCCESS: {
            const { resources } = action.payload;

            return {
                ...state,
                resources,
                error: null,
                fetching_resources: false,
                total: resources.length,
            };
        }
        case actions.GET_PROPERTY_CLOSEST_KEYNEST_STORES_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_resources: false,
                total: 0,
            };
        default:
            return baseReducer(actions)(state, action);
    }
};

export default reducer;
