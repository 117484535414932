import isNil from 'lodash/isNil';
import apiRequest from '../apiRequest';

export const paginate = async ({
	token,
	page,
	pageSize,
	q,
	property,
	sort_name,
	sort_direction,
	filters,
	id
}) => apiRequest('viewings/paginate', {
	token,
	params: {
		page,
		page_size: pageSize,
		q:         !isNil(q) ? q : undefined,
		property:  property || id,
		sort_name,
		sort_direction,
		filters,
	},
});

export const dateFilter = async ({
	token,
	start,
	end
}) => apiRequest('viewings/date-filter', {
	token,
	params: {
		start,
		end
	}
});

export const find = ({ id, token, params }) =>
    apiRequest(`viewings/${id}`, {
        method: 'GET',
        token,
		params
    });

export const create = ({ token, ...rest }) =>
    apiRequest('viewings', {
        method: 'POST',
        token,
        data: rest
    });

export const propertyKeyTravelChargePreview = ({ token, ...rest }) =>
	apiRequest(`/viewings/property-key-travel-charge/preview`, {
        method: 'POST',
        token,
        params: rest
    });

export const completePayment = (viewingId, token, discountId = undefined) =>
	apiRequest(`/viewings/${viewingId}/complete-payment`, {
		method: 'POST',
		token,
		data: {
			discount_id: discountId
		}
	});

export const confirmedAppointment = ({ id, token }) =>
	apiRequest(`/viewings/${id}/appointment`, {
		method: 'GET',
		token
	});

export const validate = ({ token, ...data }) =>
	apiRequest('viewings/validate', {
		method: 'POST',
		token,
		data
	});

export const update = ({ id, token, ...rest }) =>
    apiRequest(`viewings/${id}`, {
        method: 'PUT',
        token,
        data: rest
    });

export const cancel = ({ id, token, ...data }) =>
	apiRequest(`/viewings/${id}/cancel`, {
		method: 'POST',
		token,
		data
	});

export const destroy = ({ id, token }) =>
    apiRequest(`viewings/${id}`, {
        method: 'DELETE',
        token
    });

export const confirmedAppointmentOverrun = ({ id, token }) =>
	apiRequest(`/viewings/${id}/appointment/overrun`, {
		method: 'GET',
		token
	});

export const updateConfirmedAppointmentOverrun = ({ id, token, ...data }) =>
	apiRequest(`/viewings/${id}/appointment/overrun`, {
		method: 'PUT',
		token,
		data
	});