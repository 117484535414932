import axios from './axios'
import { get } from 'lodash'

/**
 * @param {string} uri
 * @param {object} opts
 * @param {string|undefined} opts.method
 * @param {object|undefined} opts.params
 * @param {object|undefined} opts.data
 * @param {string|undefined} opts.token
 */
export default (uri, opts) => new Promise((resolve, reject) => {
    if (uri.charAt(0) !== '/') {
        // Conveniently add a '/' to the start of the
        // URI if the user has not already added it.
        uri = '/' + uri;
    }

    // Get the request options.
    let url = `${process.env.REACT_APP_API_BASE_URL}/api/v1${uri}`;
    const method = get(opts, 'method', 'GET')
    const params = get(opts, 'params', undefined)
    const data = get(opts, 'data', undefined)
    const headers = get(opts, 'headers', {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    });
    const responseType = get(opts, 'responseType', undefined);

    // Add the authorization token if given.
    if (opts.token) {
        headers['Authorization'] = `Bearer ${opts.token}`
    }
    
    axios({
        method,
        url,
        data,
        params,
        headers,
        responseType,
    })
    .then(resolve)
    .catch(reject);
})