import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDocumentsToRead } from '../api/hooks';

const {
    REACT_APP_TERMS_AND_CONDITIONS_DOCUMENT_CATEGORY_ID,
    REACT_APP_KEYHOLDER_DOCUMENT_CATEGORY_ID,
    REACT_APP_PRE_PAY_DOCUMENT_CATEGORY_ID,
} = process.env;

const DocumentsReadRestrictedComponent = ({ children }) => {
    const location = useLocation();
    const {token, user} = useSelector(state => {
        return {
            token: state.auth.token,
            user: state.auth.user
        }
    });
    
    const {
        documents,
        isLoading: documentsIsLoading,
        isError: documentsIsError,
        error: documentsError,
        refetch: refetchDocuments,
    } = useDocumentsToRead({ token }, {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });

    if (
        !documentsIsLoading
        && !documentsIsError
        && !!documents
        && documents.filter(document => {
            return document.category_id === parseInt(REACT_APP_TERMS_AND_CONDITIONS_DOCUMENT_CATEGORY_ID, 10)
                || document.category_id === parseInt(REACT_APP_KEYHOLDER_DOCUMENT_CATEGORY_ID, 10)
                || (
                    document.category_id === parseInt(REACT_APP_PRE_PAY_DOCUMENT_CATEGORY_ID, 10)
                    && !user?.client?.pre_pay
                );
        }).length > 0
        && location
        && location.pathname !== '/dashboard'
    ) {
        return <Redirect to="/dashboard" />;
    }

    return children;
}

export default DocumentsReadRestrictedComponent;