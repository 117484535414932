import { all, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { sagaApiRequest } from '../helpers';
import * as keynestStoresAPI from '../../api/keynestStores';

export function* getPostcodeClosestKeynestStoresRequest() {
    yield takeEvery(actions.GET_POSTCODE_CLOSEST_KEYNEST_STORES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: keynestStoresAPI.closestToPostcode,
            args: payload.data,
            onSuccess: actions.GET_POSTCODE_CLOSEST_KEYNEST_STORES_SUCCESS,
            onError: actions.GET_POSTCODE_CLOSEST_KEYNEST_STORES_ERROR
        });
    });
}

export function* getPropertyClosestKeynestStoresRequest() {
    yield takeEvery(actions.GET_PROPERTY_CLOSEST_KEYNEST_STORES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: keynestStoresAPI.closestToProperty,
            args: payload.data,
            onSuccess: actions.GET_PROPERTY_CLOSEST_KEYNEST_STORES_SUCCESS,
            onError: actions.GET_PROPERTY_CLOSEST_KEYNEST_STORES_ERROR
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(getPostcodeClosestKeynestStoresRequest),
        fork(getPropertyClosestKeynestStoresRequest),
    ]);
}
