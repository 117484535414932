import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
    Alert,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
} from 'reactstrap';
import ApiErrorAlert from '../../components/Alerts/ApiErrorAlert';
import { getApiErrorMessages } from '../../helpers/apiMessages';
import { passwordResetEmail } from '../../redux/auth/actions';
import logo from '../../assets/img/brand/viewber_logo.png';

const { REACT_APP_API_KEY } = process.env;

class PasswordResetEmail extends Component {
    state = {
        email: '',
        reset_emailing: false,
    };

    constructor(props) {
        super(props);

        this.handlePasswordResetEmail = this.handlePasswordResetEmail.bind(this);
        this.handleUpdateInputValue = this.handleUpdateInputValue.bind(this);
    }

    handlePasswordResetEmail(evt) {
        const { passwordResetEmail } = this.props;
        const { email } = this.state;
        const data = {
            api_key: REACT_APP_API_KEY,
            email,
        };

        evt.preventDefault();

        this.setState({
            reset_emailing: true,
        });

        passwordResetEmail({ data });
    }

    handleUpdateInputValue(evt) {
        this.setState({
            [evt.target.name]: evt.target.value,
        });
    }

    componentDidUpdate(prevProps) {
        const { errors, password_reset_requested } = this.props;
        const { reset_emailing } = this.state;

        if(
            reset_emailing === true
            && errors.length > 0
        ) {
            this.setState({
                reset_emailing: false,
            });
        }

        else if(
            reset_emailing === true
            && password_reset_requested === true
        ) {
            this.setState({
                email: '',
                reset_emailing: false,
            });
        }
    }

    render() {
        const { errors, password_reset_requested, token } = this.props;
        const { email, reset_emailing } = this.state;
        const passwordResetEmailButtonIconClassName = reset_emailing === true
            ? 'fa fa-fw fa-spinner fa-spin'
            : 'fa fa-fw fa-envelope';

        if(token) {
            return <Redirect to="/dashboard" />;
        }

        return (
            <>
                <Helmet>
                    <title>Password Reset Request | Viewber Dashboard</title>
                </Helmet>
                <div className="app flex-row align-items-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col className="col-lg-6 col-md-8">
                                <Card>
                                    <CardBody className="text-center">
                                        <Row className="mb-3">
                                            <Col className="col-md-12 text-center">
                                                <img
                                                    src={logo}
                                                    alt="Viewber Logo"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col className="col-md-12 text-center">
                                                <span>Request Password Reset</span>
                                            </Col>
                                        </Row>
                                        <ApiErrorAlert errors={errors} />
                                        {
                                            password_reset_requested === true
                                                ? (
                                                    <Row className="mb-3">
                                                        <Col className="col-md-12 text-center">
                                                            <Alert color="success">
                                                                <i className="fa fa-fw fa-envelope"></i>
                                                                {' '}
                                                                Please check your email for instructions on how to reset your password.
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                )
                                                : null
                                        }
                                        <Form onSubmit={this.handlePasswordResetEmail}>
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-fw fa-envelope"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={email}
                                                    onChange={this.handleUpdateInputValue}
                                                    placeholder="e.g. john.smith@acme.com"
                                                    aria-label="Email"
                                                />
                                            </InputGroup>
                                            <Row>
                                                <Col className="col-md-5 col-5 text-left">
                                                    <Button
                                                        onClick={this.handlePasswordResetEmail}
                                                        type="submit"
	                                                    className="px-2 btn btn-primary btn-block"
                                                        disabled={reset_emailing}
                                                        color="success"
                                                        block
                                                    >
                                                        <i className={passwordResetEmailButtonIconClassName}></i>
                                                        {' '}
                                                        Request Password Reset
                                                    </Button>
                                                </Col>
                                                <Col className="col-md-7 col-7 text-right pl-0">
                                                    <Link to="/login">Back To Login</Link>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    const { error, password_reset_requested, token } = state.auth;
    const errors = getApiErrorMessages(error);

    return {
        errors,
        password_reset_requested,
        token,
    };
};

const mapDispatchToProps = {
    passwordResetEmail,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordResetEmail);
