import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { DefaultLayout } from './containers';
import ErrorBoundary from './ErrorBoundary';
import ErrorReportingContext from './ErrorReportingContext';
import MaintenanceChecker from './MaintenanceChecker';
import GuestComponent from './components/GuestComponent';
import { Auth, AuthAs, Login, TwoFactorPage, Page404, PasswordReset, PasswordResetEmail, SignInReapit } from './views/Pages';
import './scss/style.scss';
import RefreshOneTimePassword from "./views/Pages/RefreshOneTimePassword";

class App extends Component {

    render() {
        const { external_four_zero_four } = this.props;

        return (
            <>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/login" name="Login Page" render={props => (
                            <ErrorBoundary>
                                <ErrorReportingContext context='/login'>
                                    <GuestComponent>
                                        <MaintenanceChecker>
                                            <Login {...props} />
                                        </MaintenanceChecker>
                                    </GuestComponent>
                                </ErrorReportingContext>
                            </ErrorBoundary>
                        )} />
                        <Route exact path="/2fa" name="2FA Page" render={props => (
                            <ErrorBoundary>
                                <ErrorReportingContext context='/2fa'>
                                    <GuestComponent>
                                        <MaintenanceChecker>
                                            <TwoFactorPage {...props} />
                                        </MaintenanceChecker>
                                    </GuestComponent>
                                </ErrorReportingContext>
                            </ErrorBoundary>
                        )} />
                        <Route exact path="/signin-reapit" name="Sign In with Reapit" render={props => (
                            <ErrorBoundary>
                                <ErrorReportingContext context='/signin-reapit'>
                                    <GuestComponent>
                                        <MaintenanceChecker>
                                            <SignInReapit {...props} />
                                        </MaintenanceChecker>
                                    </GuestComponent>
                                </ErrorReportingContext>
                            </ErrorBoundary>
                        )} />
                        <Route exact path="/auth" name="Auth" render={props => (
                            <ErrorBoundary>
                                <ErrorReportingContext context='/auth'>
                                    <MaintenanceChecker>
                                        <Auth {...props} />
                                    </MaintenanceChecker>
                                </ErrorReportingContext>
                            </ErrorBoundary>
                        )} />
                        <Route exact path="/auth-as" name="Auth As" render={props => (
                            <ErrorBoundary>
                                <ErrorReportingContext context='/auth-as'>
                                    <MaintenanceChecker>
                                        <AuthAs {...props} />
                                    </MaintenanceChecker>
                                </ErrorReportingContext>
                            </ErrorBoundary>
                        )} />
                        <Route
                            exact
                            path="/password/reset/:token/:email"
                            name="Password Reset Page"
                            render={props => (
                                <ErrorBoundary>
                                    <ErrorReportingContext context='/password/reset/:token/:email'>
                                        <GuestComponent>
                                            <MaintenanceChecker>
                                                <PasswordReset {...props} />
                                            </MaintenanceChecker>
                                        </GuestComponent>
                                    </ErrorReportingContext>
                                </ErrorBoundary>
                            )}
                        />
                        <Route
                            exact
                            path="/password/reset"
                            name="Password Reset Request Page"
                            render={props => (
                                <ErrorBoundary>
                                    <ErrorReportingContext context='/password/reset'>
                                        <GuestComponent>
                                            <MaintenanceChecker>
                                                <PasswordResetEmail {...props} />
                                            </MaintenanceChecker>
                                        </GuestComponent>
                                    </ErrorReportingContext>
                                </ErrorBoundary>
                            )}
                        />
                        <Route
                            exact
                            path="/refresh-one-time-password"
                            name="Refresh One Time Password"
                            render={props => (
                                <ErrorBoundary>
                                    <ErrorReportingContext context='/refresh-one-time-password'>
                                        <GuestComponent>
                                            <MaintenanceChecker>
                                                <RefreshOneTimePassword {...props} />
                                            </MaintenanceChecker>
                                        </GuestComponent>
                                    </ErrorReportingContext>
                                </ErrorBoundary>
                            )}
                        />
                        {
                            external_four_zero_four === true
                                ? <Page404 />
                                : <Route path="/" name="Home" component={DefaultLayout} />
                        }
                    </Switch>
                    <ToastContainer
                        hideProgressBar
                        bodyClassName="color-black"
                    />
                </BrowserRouter>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { external_four_zero_four } = state.auth;

    return {
        external_four_zero_four,
    };
};

export default connect(
    mapStateToProps
)(App);
