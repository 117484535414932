import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '../Form';
import FormikTextInput from './FormikTextInput';
import FormikNumberInput from './FormikNumberInput';

const FormikViewingCollectionInputs = ({
    inputIdPrefix,
    inputNamesMap
}) => {
    const makeInputId = inputId => {
        if (inputIdPrefix) {
            return `${inputIdPrefix}-${inputId}`;
        }
        return inputId;
    }

    const makeInputName = inputName => {
        if (inputNamesMap && inputNamesMap[inputName]) {
            return inputNamesMap[inputName];
        }
        return inputName;
    }

    return (
        <React.Fragment>
            <FormGroup>
                <FormikTextInput
                    id={makeInputId('viewing-collection-name-input')}
                    name={makeInputName('collection_name')}
                    label='Contact Name'
                    placeholder='Enter the Contact Name'
                />
            </FormGroup>

            <FormGroup>
                <FormikTextInput
                    id={makeInputId('viewing-collection-phone-input')}
                    name={makeInputName('collection_phone')}
                    label='Contact Phone'
                    placeholder='Enter the Contact Phone'
                />
            </FormGroup>

            <FormGroup>
                <FormikTextInput
                    id={makeInputId('viewing-collection-line-1-input')}
                    name={makeInputName('collection_line_1')}
                    label='Address Line 1'
                    placeholder='Enter the Address Line 1'
                />
            </FormGroup>

            <FormGroup>
                <FormikTextInput
                    id={makeInputId('viewing-collection-line-2-input')}
                    name={makeInputName('collection_line_2')}
                    label='Address Line 2'
                    placeholder='Enter the Address Line 2'
                />
            </FormGroup>

            <FormGroup>
                <FormikTextInput
                    id={makeInputId('viewing-collection-city-input')}
                    name={makeInputName('collection_city')}
                    label='City'
                    placeholder='Enter the City'
                />
            </FormGroup>

            <FormGroup>
                <FormikTextInput
                    id={makeInputId('viewing-collection-postcode-input')}
                    name={makeInputName('collection_postcode')}
                    label='Postcode'
                    placeholder='Enter the Postcode'
                />
            </FormGroup>

            <FormGroup>
                <FormikNumberInput
                    id={makeInputId('viewing-collection-travel-time-input')}
                    name={makeInputName('collection_travel_time')}
                    label='Travel Time (mins)'
                    placeholder='Enter the Travel Time (mins)'
                    helpText='Please calculate the driving travel time, in minutes, between the property and the collection address using Google Maps.'
                />
            </FormGroup>
        </React.Fragment>
    )
}

FormikViewingCollectionInputs.propTypes = {
    /**
     * A prefix for the input ids.
     */
    inputIdPrefix: PropTypes.string,

    /**
     * A map of names for the input to use
     * instead of the default name.
     * E.g.
     * {
     *    'collection_name': 'new_collection_name,
     *    'collection_phone': 'new_collection_phone,
     * }
     */
    inputNamesMap: PropTypes.object
}

export default FormikViewingCollectionInputs;