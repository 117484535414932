import * as authAPI from '../index';
import {useQuery} from '@tanstack/react-query'

const showCurrentUserQueryKey = () => ['auth', 'current-user'];

export const useCurrentUser = (token, options) => {
  const {
    data,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(
    showCurrentUserQueryKey(),
    () => new Promise((resolve, reject) => {
        authAPI.getProfile({token})
        .then(res => resolve(res.data.resource))
        .catch(reject);
    }),
    options
  );

  return {
    user: data,
    isLoading,
    isError,
    error,
    refetch
  }
}