import Rollbar from 'rollbar';

const rollbar = new Rollbar({
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    enabled: process.env.REACT_APP_ENABLE_ROLLBAR === 'true',
    payload: {
        environment: process.env.NODE_ENV,
    }
});


export default rollbar;