import React from 'react';
import FormGroup from '../Form/FormGroup';
import FormikSelect from './FormikSelect';

const FormikFormGroupSelect = props => {
    return (
        <FormGroup>
            <FormikSelect {...props} />
        </FormGroup>
    )
}

FormikFormGroupSelect.propTypes = FormikSelect.propTypes;

export default FormikFormGroupSelect;