import actions from './actions';
import baseReducer from '../base/reducer';
import { updatePaginatedResourcesFromResource } from '../../helpers/paginatedResources';

const initial_state = {
    created: false,
    current_page: 1,
    destroyed: false,
    error: null,
    fetching_resources: false,
    resource: null,
    resources: [],
    paginated_resources: {
        1: []
    },
    order: null,
    total: 0,
    updated: false
};

const reducer = (state = initial_state, action) => {
    switch(action.type) {
        case actions.CHANGE_PAGE_RESOURCES:
            return {
                ...state,
                current_page: action.payload.data.page
            };
        case actions.CLEAR_METADATA_RESOURCES: {
            return {
                ...state,
                error: null,
                current_page: 1,
                destroyed: false,
                fetching_resources: false,
                paginated_resources: {
                    1: []
                },
            };
        }
        case actions.CLEAR_METADATA_RESOURCE_EDIT:
            return {
                ...state,
                error: null,
                updated: false,
                created: false,
                resource: null
            };
        case actions.CLEAR_METADATA_RESOURCE_CREATE:
            return {
                ...state,
                error: null,
                // created: false,
                resource: null,
                order: null,
            };
        case actions.CREATE_RESOURCE_REQUEST:
            return {
                ...state,
                error: null,
                created: false,
                order: null,
            };
        case actions.CREATE_RESOURCE_SUCCESS: {
	        const { data } = action.payload;

            return {
                ...state,
                error: null,
                created: true,
                resource: data,
                order: data.current_order ? data.current_order : null
            };
        }
        case actions.CREATE_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
                created: false,
                order: null
            };
        case actions.DESTROY_RESOURCE_REQUEST:
            return {
                ...state,
                error: null,
                destroyed: false
            };
        case actions.DESTROY_RESOURCE_SUCCESS:
            return {
                ...state,
                error: null,
                destroyed: true,
                // resource: action.payload.resource,
            };
        case actions.DESTROY_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
                destroyed: false
            };
        case actions.GET_PAGINATED_RESOURCES_REQUEST: {
            const { data } = action.payload;
            return {
                ...state,
                error: null,
                fetching_resources: true,
                current_page: data.page,
                // If searching, reset all pages
                paginated_resources: (
                        typeof data.q !== 'undefined'
                        && data.q !== ''
                    )
                    ? {
                        1: []
                    }
                    // Otherwise reset page I'm fetching
                    : {
                        ...state.paginated_resources,
                        [data.page]: []
                    },
                total: 0,
            };
        }
        case actions.GET_PAGINATED_RESOURCES_SUCCESS: {
            const { data, total, current_page } = action.payload;
            return {
                ...state,
                current_page: current_page,
                error: null,
                fetching_resources: false,
                paginated_resources: {
                    ...state.paginated_resources,
                    [current_page]: data,
                },
                total: total,
            };
        }
        case actions.GET_PAGINATED_RESOURCES_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_resources: false,
                total: 0
            };
        case actions.UPDATE_RESOURCE_REQUEST:
            return {
                ...state,
                created: false,
                error: null,
                updated: false
            };
        case actions.UPDATE_RESOURCE_SUCCESS: {
            const { resource } = action.payload;
            return {
                ...state,
                error: null,
                updated: true,
                resource: action.payload.resource,
                paginated_resources: updatePaginatedResourcesFromResource(state.paginated_resources, resource)
            };
        }
        case actions.UPDATE_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
                updated: false
            };
        default:
            return baseReducer(actions)(state, action);
    }
};

export default reducer;
