import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Button,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row
} from 'reactstrap';
import FormInput from '../../components/FormInput';
import ApiErrorAlert from '../../components/Alerts/ApiErrorAlert';
import { getApiErrorMessages } from '../../helpers/apiMessages';
import { verifyTwoFactorCode, resendTwoFactorCode, resetLoginError } from '../../redux/auth/actions';
import loginLogo from '../../assets/img/brand/viewber_logo.png';
import queryString from "query-string";
import {Redirect} from "react-router-dom";

const { REACT_APP_API_KEY } = process.env;

export class TwoFactorPage extends Component {
    constructor(props) {
        super(props);

        let urlParams = {}

        const { token } = this.props;

        if (props.location) {
            urlParams = queryString.parse(props.location.search);
        }
        
        let t = token
        if (typeof urlParams === 'object' && urlParams.t !== null && typeof urlParams.t !== 'undefined') {
            t = urlParams.t
        }

        this.state = {
            code: '',
            token: t,
            redirectToLogin: false
        };

        if (t === null) {
            this.state = {redirectToLogin: true};
        }

        this.verifyTwoFactorCode = this.verifyTwoFactorCode.bind(this);
        this.resendTwoFactorCode = this.resendTwoFactorCode.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
    }

    verifyTwoFactorCode(evt) {
        evt.preventDefault();

        const { verifyTwoFactorCode } = this.props;
        const { code, token } = this.state;

        verifyTwoFactorCode({
            data: {
                api_key: REACT_APP_API_KEY,
                code,
                token,
            }
        });
    };

    resendTwoFactorCode(evt) {
        evt.preventDefault();
        const { resendTwoFactorCode } = this.props;
        const { token } = this.state;

        const data = {
            api_key: REACT_APP_API_KEY,
            token,
        };

        resendTwoFactorCode({data});
    }

    updateInputValue(evt) {
        evt.preventDefault();

        this.setState({
            [evt.target.name]: evt.target.value,
        });
    }

    render() {

        const { errors, verifying } = this.props
        const { redirectToLogin } = this.state

        if (redirectToLogin == true) {
            return <Redirect to="/login" />;
        }

        const loginButtonIconClassName = typeof verifying !== 'undefined' && verifying === true
            ? 'fa fa-spinner fa-spin'
            : 'fa fa-sign-in';

        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <Card className="p-2">
                                <CardBody>
                                    <img
                                        src={loginLogo}
                                        alt="Viewber logo"
                                        className="img-fluid"
                                    />
                                    <p className="text-muted margin-top">Two Factor Verification</p>
                                    <ApiErrorAlert errors={errors} />
                                    <Form innerRef="login-form">
                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-fw fa-lock"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <FormInput
                                                type="text"
                                                name="code"
                                                placeholder="code"
                                                disabled={verifying}
                                                onChange={this.updateInputValue}
                                            />
                                        </InputGroup>
                                        <Row>
                                            <Col className="col-md-6">
                                                <Button
                                                    type="submit"
                                                    color="light-blue"
                                                    className="px-2"
                                                    disabled={verifying}
                                                    onClick={this.verifyTwoFactorCode}
                                                    block
                                                >
                                                    <i className={loginButtonIconClassName}></i>
                                                    {' '}
                                                    Verify
                                                </Button>
                                            </Col>
                                            <Col className="col-md-6 text-right">
                                                <Button
                                                    color="link"
                                                    className="px-0 text-right"
                                                    onClick={this.resendTwoFactorCode}
                                                >
                                                    Re-send the email
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <hr />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        error,
        token,
        verifying
    } = state.auth;

    const errors = getApiErrorMessages(error);

    return {
        errors,
        token,
        verifying
    };
};

const mapDispatchToProps = {
    resendTwoFactorCode,
    verifyTwoFactorCode,
    resetLoginError,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TwoFactorPage);
