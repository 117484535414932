import * as viewingLengthsAPI from '../index';
import { useQuery } from '@tanstack/react-query'
const viewingLengthsWithTypeAndCategoryKey = ['addon-viewing-lengths-with-type-and-category', 'all'];

export const useAddonViewingLengthsWithTypeAndCategory = (
  token,
  options = {
    staleTime: 5 * 60 * 1000 // 5 minutes.
  }
) => {
  const {
    data,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(
    viewingLengthsWithTypeAndCategoryKey,
    () => new Promise((resolve, reject) => {
      viewingLengthsAPI.addonsSearch({token})
        .then(res => resolve(res.data.resources))
        .catch(reject);
    }),
    options
  );
  
  return {
    viewingLengths: data,
    isLoading,
    isError,
    error,
    refetch
  }
}