import createActionName from '../createActionName';
import { reducerName } from './variables';

export const GET_ALL_RESOURCES_REQUEST = createActionName(reducerName, 'GET_ALL_RESOURCES_REQUEST');
export const GET_ALL_RESOURCES_SUCCESS = createActionName(reducerName, 'GET_ALL_RESOURCES_SUCCESS');
export const GET_ALL_RESOURCES_ERROR = createActionName(reducerName, 'GET_ALL_RESOURCES_ERROR');

// actions
const actions = {
  	GET_ALL_RESOURCES_REQUEST,
  	GET_ALL_RESOURCES_SUCCESS,
  	GET_ALL_RESOURCES_ERROR,
};

// action creators
export const getAllResources = payload => ({
    type: actions.GET_ALL_RESOURCES_REQUEST,
    payload
});

export default actions;
