import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '../Form';
import FormikTextInput from './FormikTextInput';

const FormikViewingKeysafeInputs = ({
    inputIdPrefix,
    inputNamesMap
}) => {
    const makeInputId = inputId => {
        if (inputIdPrefix) {
            return `${inputIdPrefix}-${inputId}`;
        }
        return inputId;
    }

    const makeInputName = inputName => {
        if (inputNamesMap && inputNamesMap[inputName]) {
            return inputNamesMap[inputName];
        }
        return inputName;
    }

    return (
        <React.Fragment>
            <FormGroup>
                <FormikTextInput
                    id={makeInputId('viewing-keycode-input')}
                    name={makeInputName('keycode')}
                    label='Keycode'
                    placeholder='Enter the Keycode'
                />
            </FormGroup>

            <FormGroup>
                <FormikTextInput
                    id={makeInputId('viewing-key-location-input')}
                    name={makeInputName('key_location')}
                    label='Key Safe Location'
                    placeholder='Enter the Key Safe Location'
                />
            </FormGroup>

            <FormGroup>
                <FormikTextInput
                    id={makeInputId('viewing-key-comments-input')}
                    name={makeInputName('key_comments')}
                    label='Key comments'
                    placeholder='Enter the Key comments'
                />
            </FormGroup>
        </React.Fragment>
    )
}

FormikViewingKeysafeInputs.propTypes = {
    /**
     * A prefix for the input ids.
     */
    inputIdPrefix: PropTypes.string,

    /**
     * A map of names for the input to use
     * instead of the default name.
     * E.g.
     * {
     *    'key_location_name': 'different_key_location_name',
     *    'key_comments': 'different_key_comments_name,
     * }
     */
    inputNamesMap: PropTypes.object
}

export default FormikViewingKeysafeInputs;