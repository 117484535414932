import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

class GuestComponent extends Component {
    render() {
        const { logged_in, must_complete_mfa } = this.props;

        if (logged_in === true && must_complete_mfa !== true) {
            return <Redirect to="/" />
        }

        return this.props.children;
    }
}

const mapStateToProps = (state) => {
    const { token } = state.auth;

    return {
        logged_in: typeof token !== 'undefined' && token !== null,
        must_complete_mfa: state.auth.must_complete_mfa === 'undefined' ? null : state.auth.must_complete_mfa
    }
};

export default connect(
    mapStateToProps
)(GuestComponent);
