import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Label from './Label';
import FormText from './FormText';
import DateTimeBase from 'react-datetime';
import { isString } from 'lodash';

const DateTime = ({
    id,
    name,
    label,
    value: _value,
    valueFormat,
    displayDateFormat,
    displayTimeFormat,
    placeholder,
    disabled,
    helpText,
    invalid,
    error,
    timeConstraints,
    locale,
    viewMode,
    isValidDate,
    closeOnTab,
    closeOnSelect,
    onChange,
    onBlur
}) => {
    const helpTextId = id ? `${id}-helpText` : undefined;
    
    let value = _value;

    if (isString(value)) {
        // If the value is a string, we transform it
        // to a Date object.
        value = moment(value, valueFormat).toDate();
    } else if (moment.isMoment(value)) {
        // If the value is a moment object, we will
        // transform it to a Date object.
        value = value.toDate();
    }

    // Otherwise we will pass the value through as-is
    // allowing for values such as 'null' and 'undefined'.
    // We can't account for every type of value that gets
    // recieved so we just transform strings and moment
    // objects into Date objects.

    return (
        <React.Fragment>
            {label &&
                <Label for={id}>{label}</Label>
            }
            <DateTimeBase
                id={id}
                name={name}
                value={value}
                dateFormat={displayDateFormat}
                timeFormat={displayTimeFormat}
                placeholder={placeholder}
                disabled={disabled}
                timeConstraints={timeConstraints}
                locale={locale}
                viewMode={viewMode}
                isValidDate={isValidDate}
                closeOnTab={closeOnTab}
                closeOnSelect={closeOnSelect}
                inputProps={{
                    id: name,
                    name: name,
                    readOnly: true,
                    className: invalid ? 'form-control is-invalid' : 'form-control',
                    disabled: disabled ? true : false,
                }}
                onChange={onChange}
                onBlur={onBlur}
            />
            {error &&
                (Array.isArray(error)
                    ? error.map((e,i) => <div key={i} className='text-danger font-sm'>{e}</div>)
                    : <div className='text-danger font-sm'>{error}</div>
                )
            }
            {helpText &&
                <FormText id={helpTextId}>
                    {helpText}
                </FormText>
            }
        </React.Fragment>
    );
}

DateTime.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,

    /**
     * Represents the selected date by the component, in order to
     * use it as a controlled component.
     */
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),

    /**
     * Defines the format used to parse the value if the value is
     * a string. It accepts any Moment.js format.
     */
    valueFormat: PropTypes.string,

    /**
     * Defines the format for the displayed date. It accepts any Moment.js date format
     * (not in localized format). If true the date will be displayed using
     * the defaults for the current locale. If false the datepicker is disabled
     * and the component can be used as timepicker, see available units docs.
     */
    displayDateFormat: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),

    /**
     * Defines the format for the displayed time. It accepts any Moment.js time
     * format (not in localized format). If true the time will be displayed using
     * the defaults for the current locale. If false the timepicker is disabled
     * and the component can be used as datepicker, see available units docs.
     */
    displayTimeFormat: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),

    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    helpText: PropTypes.node,
    invalid: PropTypes.bool,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),

    /**
     * Add some constraints to the timepicker. It accepts an object with the
     * format { hours: { min: 9, max: 15, step: 2 }}, this example means the
     * hours can't be lower than 9 and higher than 15, and it will change adding
     * or subtracting 2 hours everytime the buttons are clicked. The constraints
     * can be added to the hours, minutes, seconds and milliseconds.
     */
    timeConstraints: PropTypes.shape({
        hours: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number
        }),
        minutes: PropTypes.shape({
            step: PropTypes.number
        })
    }),

    locale: PropTypes.string,

    /**
     * The default view to display when the picker
     * is shown ('years', 'months', 'days', 'time').
     */
    viewMode: PropTypes.oneOf(['time', 'days', 'months', 'years']),

    /**
     * Define the dates that can be selected. The function receives
     * (currentDate, selectedDate) and shall return a true or false
     * whether the currentDate is valid or not. See selectable dates.
     */
    isValidDate: PropTypes.func,
    
    closeOnTab: PropTypes.bool,
    closeOnSelect: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
}

export default DateTime;