import isNil from 'lodash/isNil';
import apiRequest from '../apiRequest';

export const forAppointment = ({ id, token, params }) =>
    apiRequest(`chats/for-appointment/${id}`, {
        method: 'GET',
        token,
		params
    });

export const paginate = async ({
    token,
    orderBy,
    orderDirection,
    limit,
    hasUserUnreadMessages,
    appointmentIsCurrentForViewing,
    expand
}) => apiRequest('chats', {
    token,
    params: {
        order_by: orderBy,
        order_direction: orderDirection,
        limit,
        has_user_unread_messages: !isNil(hasUserUnreadMessages)
            ? !!hasUserUnreadMessages ? 1 : 0
            : undefined,
        appointment_is_current_for_viewing: !isNil(appointmentIsCurrentForViewing)
            ? !!appointmentIsCurrentForViewing ? 1 : 0
            : undefined,
        expand
    }
});