import React, {useState} from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container, Form, InputGroup, InputGroupAddon, InputGroupText,
    Row
} from 'reactstrap';
import loginLogo from "../../../assets/img/brand/viewber_logo.png";
import LoadingAlert from "../../../components/Alerts/LoadingAlert";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import Alert from "../../../components/Alerts/Alert";
import {useMutation, useQuery} from "@tanstack/react-query";
import FormInput from "../../../components/FormInput";

const RefreshOneTimePassword = () => {
    const [otp, setOtp] = useState("");
    const { search } = useLocation();
    const queryParams = queryString.parse(search);

    const {error, isLoading, mutate, data} = useMutation({
        mutationFn: async () => {
            if (queryParams?.email) {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/refresh-one-time-password`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json',
                    },
                    body: `email=${encodeURIComponent(queryParams.email?.toString())}&otp=${otp}`
                })

                if (!response.ok) {
                    let error = await response.json()

                    throw new Error(error.message)
                }

                return await response.json()
            }
        }
    })

    const handleRefreshOneTimePassword = () => mutate();

    return (
        <div className="app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col md="6">
                        <Card className="p-2">
                            <CardBody>
                                <img
                                    src={loginLogo}
                                    alt="Viewber logo"
                                    className="img-fluid mb-3"
                                />

                                {
                                    isLoading && <LoadingAlert msg="Refreshing one time password"/>
                                }

                                {
                                    error && error.message && (<Alert color="danger">{error.message}</Alert>)
                                }

                                {
                                    !isLoading && !data?.message && (
                                        <>
                                            <p className="text-muted margin-top">Please enter your previous one time password</p>
                                            <InputGroup className="mb-4">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-fw fa-key"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput
                                                    type="password"
                                                    name="password"
                                                    placeholder="Previous one time password"
                                                    disabled={isLoading}
                                                    onChange={(event) => setOtp(event.target.value)}
                                                />
                                            </InputGroup>
                                            <Button
                                                type="submit"
                                                color="light-blue"
                                                className="px-2"
                                                disabled={isLoading}
                                                onClick={handleRefreshOneTimePassword}
                                                block
                                            >
                                                <i className="fa fa-sign-in"></i>
                                                {' '}
                                                Request new one time password
                                            </Button>
                                        </>
                                    )
                                }

                                { data && data?.message && <p className="text-muted margin-top">{data.message}</p>}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default RefreshOneTimePassword;