import React from 'react';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { DateTime } from '../Form';

const FormikDateTime = ({
    id,
    name,
    label,
    valueFormat,
    displayDateFormat,
    displayTimeFormat,
    placeholder,
    disabled,
    helpText,
    timeConstraints,
    isValidDate,
    closeOnTab,
    closeOnSelect
}) => (
    <Field name={name}>
        {({
            field, // { name, value, onChange, onBlur }
            form: { touched, errors, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
        }) => (
            <DateTime
                id={id}
                name={field.name}
                label={label}
                value={field.value}
                valueFormat={valueFormat}
                displayDateFormat={displayDateFormat}
                displayTimeFormat={displayTimeFormat}
                placeholder={placeholder}
                disabled={disabled}
                helpText={helpText}
                invalid={meta.touched && !isNil(meta.error)}
                error={meta.error}
                timeConstraints={timeConstraints}
                isValidDate={isValidDate}
                closeOnTab={closeOnTab}
                closeOnSelect={closeOnSelect}
                onChange={date => {
                    setFieldValue(field.name, date.format(valueFormat))
                }}
                onBlur={() => {
                    setFieldTouched(field.name)
                }}
            />
        )}
    </Field>
)

FormikDateTime.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,

    /**
     * Defines the format used to parse the value if the value is
     * a string and defines the format that gets passed back to
     * formik when the value is changed.
     */
     valueFormat: PropTypes.string,
 
     /**
      * Defines the format for the displayed date. It accepts any Moment.js date format
      * (not in localized format). If true the date will be displayed using
      * the defaults for the current locale. If false the datepicker is disabled
      * and the component can be used as timepicker, see available units docs.
      */
     displayDateFormat: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
     ]),
 
     /**
      * Defines the format for the displayed time. It accepts any Moment.js time
      * format (not in localized format). If true the time will be displayed using
      * the defaults for the current locale. If false the timepicker is disabled
      * and the component can be used as datepicker, see available units docs.
      */
     displayTimeFormat: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),

    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    helpText: PropTypes.node,
    timeConstraints: PropTypes.shape({
        hours: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number
        }),
        minutes: PropTypes.shape({
            step: PropTypes.number
        })
    }),
    isValidDate: PropTypes.func,
    closeOnTab: PropTypes.bool,
    closeOnSelect: PropTypes.bool,
}

export default FormikDateTime;