import isNil from 'lodash/isNil';
import apiRequest from '../apiRequest';

export const get = async ({ token, params, data }) =>
    apiRequest('viewber-billing-types', {
        token,
        params,
        data
    });

export const paginate = async ({token, page, pageSize, q}) =>
    apiRequest('viewber-billing-types/paginate', {
        token,
        params: {
            page,
            page_size: pageSize,
            q: !isNil(q) ? q : undefined
        }
    });

export const find = ({ id, token }) =>
    apiRequest(`viewber-billing-types/${id}`, {
        method: 'GET',
        token
    });