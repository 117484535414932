import React from 'react';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { NumberInput } from '../Form';

const FormikNumberInput = ({
    id,
    name,
    label,
    placeholder,
    disabled,
    helpText,
    children
}) => (
    <Field name={name}>
        {({
            field, // { name, value, onChange, onBlur }
            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
        }) => (
            <NumberInput
                id={id}
                name={field.name}
                value={field.value}
                label={label}
                placeholder={placeholder}
                helpText={helpText}
                invalid={meta.touched && !isNil(meta.error)}
                disabled={disabled}
                error={meta.error}
                onChange={field.onChange}
                onBlur={field.onBlur}
            >
                {children}
            </NumberInput>
        )}
    </Field>
)

FormikNumberInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    helpText: PropTypes.string,
}

export default FormikNumberInput;