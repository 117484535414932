import React from 'react';
import PropTypes from 'prop-types';
import { animateScroll as scroll } from 'react-scroll';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useRollbarPerson } from '@rollbar/react';
import RequiresMoreDetailsModal from "./Modals/RequiresMoreDetailsModal";

const superManagerServices = [
    '/me/third-party-services',
    '/me/phone-pin',
    '/groups',
    '/users',
]

const RestrictedComponent = ({ children }) => {
    const token = useSelector(state => state.auth.token);
    const user = useSelector(state => {
        if (!state.auth.user) {
            return undefined;
        }

        return {
            id: state.auth.user.id,
            one_time_password: state.auth.user?.one_time_password,
            requires_more_details: state.auth.user?.requires_more_details,
            super_manager: state.auth.user?.super_manager
        };
    });

    useRollbarPerson(user);

    const loggedIn = (
        typeof token !== 'undefined'
        && token !== null
    );

    React.useEffect(() => {
        // We scroll to top at the mounting of restricted component as it seems
        // React leaves the scroll down if we have scrolled down to a previous page.
        // This means the scroll to top is going to be quick (1ms),
        // and the user shouldn't be noticing it
        scroll.scrollToTop({
            smooth: false,
            duration: 1,
        });
    }, []);
    
    if (!loggedIn) {
        return <Redirect to="/login" />
    }

    if (
        location
        && location.pathname !== '/dashboard'
        && user
        && user.one_time_password
    ) {
        return <Redirect to="/dashboard" />
    }

    if (
        user
        && !user?.super_manager
        && superManagerServices.includes(location?.pathname)
    ) {
        return <Redirect to="/dashboard" />
    }

    if (
        location
        && (
            location.pathname === '/properties/create'
            || location.pathname.includes('appointments/create')
        )
        && user
        && user.requires_more_details
    ) {
        return <RequiresMoreDetailsModal />
    }

    return children;
}

RestrictedComponent.propTypes = {
    children: PropTypes.node,
}

export default RestrictedComponent;
