import React from 'react';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import * as chatsApi from '../../api/chats';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Badge,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Dropdown,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { useRealtimeTotalUserUnreadChatMessages } from '@viewberapp/chat';
import { resourceBaseRoute as viewingsBaseRoute } from '../../redux/viewings/schema';

const ChatsDropdown = () => {
    const history = useHistory();
    const user = useSelector(state => state.auth.user);
    const token = useSelector(state => state.auth.token);
    const [isOpen, setIsOpen] = React.useState(false);
    const [chats, setChats] = React.useState({
        loading: 'idle',
        error: null,
        data: []
    });
    const {
        totalUnreadMessages,
    } = useRealtimeTotalUserUnreadChatMessages(user ? user.id : undefined, {
        requireAppointmentId: false,
        appointmentIsConfirmedForViewing: 1
    });

    let chatsDropdownContent;

    const loadChats = React.useCallback(
        () => {
            if (chats.loading === 'pending') {
                return;
            }
    
            setChats(prev => ({
                loading: 'pending',
                error: null,
                data: prev.data
            }));
    
            chatsApi.paginate({
                token,
                orderBy: ['user_unread_message_count', 'last_message'],
                orderDirection: 'DESC',
                limit: 3,
                appointmentIsCurrentForViewing: true,
                expand: [
                    'appointment',
                    'latest_message',
                    'latest_message.sender',
                    'user_unread_message_count'
                ]
            }).then(response => {
                setChats({
                    loading: 'idle',
                    error: null,
                    data: response.data.data
                });
            }).catch(err => {
                setChats(prev => ({
                    loading: 'idle',
                    error: err,
                    data: prev.data
                }));
            });
        },
        []
    );

    React.useEffect(() => {
        // Every time the chat dropdown is opened
        // we will reload the chats.
        if (isOpen) {
            loadChats();
        }
    }, [isOpen, loadChats]);

    React.useEffect(() => {
        // Do this on initial mount.
        loadChats();
    }, [loadChats]);

    if (chats.loading === 'pending') {
        chatsDropdownContent = (
            <div className='my-3 text-center'>
                <i className='fa fa-spinner fa-2x fa-spin'/>
            </div>
        )
    } else if (chats.error !== null) {
        chatsDropdownContent = (
            <div className='my-3 text-center'>
                <p>Loading failed</p>
                <Button onClick={loadChats}>Retry</Button>
            </div>
        )
    } else {
        if (chats.data.length === 0) {
            chatsDropdownContent = (
                <DropdownItem
                    key={`chats_empty`}
                    role="menuitem"
                    disabled
                    className='text-center'
                >
                    No chats
                </DropdownItem>
            )
        } else {
            chatsDropdownContent = (
                chats.data.map(chat => {
                    const latestMessageTextMaxLength = 150;
                    let vvId = chat.appointment ? chat.appointment.request_id : undefined;
                    let sender = (!isNil(chat.latest_message) && !isNil(chat.latest_message.sender)) ? chat.latest_message.sender.first_name : undefined;
                    let text = !isNil(chat.latest_message) ? chat.latest_message.message : undefined;
                    if (isString(text) && text.length > latestMessageTextMaxLength) {
                        text = text.substring(0, latestMessageTextMaxLength) + '...'
                    }
                    let createdAt = !isNil(chat.latest_message) ? (new Date(chat.latest_message.created_at)).toLocaleString() : undefined;
                    const className = chat.user_unread_message_count === 0
                        ? 'notification-dropdown-item read'
                        : 'notification-dropdown-item not-read'
    
                    return (
                        <DropdownItem
                            key={`chat_${chat.id}`}
                            role="menuitem"
                            className={className}
                            onClick={() => {
                                history.push(`/${viewingsBaseRoute}/${chat.appointment.request_id}`);
                            }}
                        >
                            <Row>
                                <Col xs={10}>
                                    {vvId && <h6 style={{marginBottom: '0'}}>{`VV${vvId}`}</h6>}
                                    {sender && <small className='text-muted' style={{marginRight: '0.25rem'}}>{`${sender}:`}</small>}
                                    <small className='text-muted'>{text || 'No messages'}</small>
                                    {createdAt &&
                                        <small className='d-block text-muted'>{createdAt}</small>
                                    }
                                </Col>
                                <Col xs={2} className='d-flex align-items-center justify-content-end'>
                                    {(Number(chat.user_unread_message_count) > 0) &&
                                        <Badge pill color="danger" className='position-static m-0'>{chat.user_unread_message_count}</Badge>
                                    }
                                </Col>
                            </Row>
                        </DropdownItem>
                    )
                })
            )
        }
    }

    return (
        <Dropdown className="nav-item" direction="down" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
            <DropdownToggle nav>
                <i className="fa fa-comments"></i>
                {(!isNil(totalUnreadMessages) && totalUnreadMessages !== 0) &&
                    <Badge pill color="danger">{totalUnreadMessages}</Badge>
                }
            </DropdownToggle>
            <DropdownMenu right style={{ width: '300px', right: 0 }}>
                <DropdownItem header tag="div" className="notification-dropdown-header">
                    <div className='text-center'>
                        <strong>Chats</strong>
                    </div>
                </DropdownItem>
                {chatsDropdownContent}
            </DropdownMenu>
        </Dropdown>
    )
}

export default ChatsDropdown;