import isNil from 'lodash/isNil';
import apiRequest from '../apiRequest';

export const find = ({ id, token }) =>
    apiRequest(`document-categories/${id}`, {
        method: 'GET',
        token
    });

export const paginate = async ({ token, page, pageSize, q, sort_name, sort_direction }) =>
    apiRequest('document-categories/paginate', {
        token,
        params: {
            page,
            page_size: pageSize,
            q: !isNil(q) ? q : undefined,
            sort_name,
            sort_direction
        }
    });

export const update = ({ id, token, ...rest }) =>
    apiRequest(`document-categories/${id}`, {
        method: 'PUT',
        token,
        data: rest
    });

export const destroy = ({ id, token }) =>
    apiRequest(`document-categories/${id}`, {
        method: 'DELETE',
        token
    });

export const latestDocument = ({ id, token }) =>
    apiRequest(`document-categories/${id}/latest-document`, {
        token
    });