import actions from './actions';
import baseReducer from '../base/reducer';

const initial_state = {
    date_filtered_resources: [],
    current_page: 1,
    error: null,
    fetching_resources: false,
    resources: [],
    paginated_resources: {
        1: []
    },
    total: 0,
    recurrences_total: 0,
};

const reducer = (state = initial_state, action) => {
    switch(action.type) {
        case actions.CHANGE_PAGE_RESOURCES:
            return {
                ...state,
                current_page: action.payload.data.page
            };
        case actions.CLEAR_METADATA_RESOURCES: {
            const { data } = action.payload;

            return {
                ...state,
                current_page: 1,
                error: null,
                fetching_resources: false,
                resources: [],
                paginated_resources: (
                    data.query !== ''
                    && data.query !== null
                    && typeof data.query !== 'undefined'
                )
                    ? {
                        1: []
                    }
                    : {
                        ...state.paginated_resources
                    }
            };
        }
        case actions.GET_PAGINATED_RESOURCES_REQUEST: {
            const { data } = action.payload;

            return {
                ...state,
                current_page: data.page,
                error: null,
                fetching_resources: true,
                paginated_resources: {
                    ...state.paginated_resources,
                    [data.page]: [],
                },
                total: 0,
            };
        }
        case actions.GET_PAGINATED_RESOURCES_SUCCESS: {
            const { data, meta } = action.payload;
            return {
                ...state,
                current_page: meta.current_page,
                total: meta.total,
                error: null,
                fetching_resources: false,
                paginated_resources: {
                    ...state.paginated_resources,
                    [meta.current_page]: data,
                },
            };
        }
        case actions.GET_PAGINATED_RESOURCES_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_resources: false,
                total: 0
            };
        default:
            return baseReducer(actions)(state, action);
    }

};

export default reducer;