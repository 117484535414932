import React from 'react';
import FormGroup from '../Form/FormGroup';
import FormikCheckbox from './FormikCheckbox';

const FormikFormGroupCheckbox = props => {
    return (
        <FormGroup className='form-check'>
            <FormikCheckbox {...props} />
        </FormGroup>
    )
}

FormikFormGroupCheckbox.propTypes = FormikCheckbox.propTypes;

export default FormikFormGroupCheckbox;