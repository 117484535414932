import React from 'react';

const Option = ({
    value,
    disabled,
    children,
}) => (
    <option value={value} disabled={disabled}>{children}</option>
)

export default Option;