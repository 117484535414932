import get from 'lodash/get';
import NProgress from 'nprogress';
import isObject from 'lodash/isObject';
import instance, { isAxiosError } from '../axios';
import { logout } from '../../../redux/auth/actions';

// Create a variable to hold the interceptor once it has been set.
let interceptor = undefined;

/**
 * Setup the interceptor on the axios instance which will
 * handle what needs to happen within the redux store when
 * a request returns an 'unauthenticated' error.
 * 
 * @param {object} store The redux store.
 * @returns 
 */
export const setupUnauthenticatedRequestReduxStoreIncerceptor = store => {
    // If we have already set the interceptor then we will exit.
    if (interceptor) {
        return;
    }
    
    // Set the interceptor and assign it to the interceptor variable.
    interceptor = instance.interceptors.response.use(response => {
        // Implement the redux store logic here.
        return response;
    }, error => {
        // If the response sends back an unauthenticated error,
        // then we will dispatch logout() to the store.
        if (
            isAxiosError(error)
            && error.response.status === 401
        ) {
            NProgress.done();
            NProgress.remove();
            store.dispatch(
                logout({
                    skipApi: true,
                    // Because this interceptor generally receieves many 401's
                    // at the same time, only update the state error if the state
                    // error is nil.
                    updateStateErrorOnlyIfStateErrorIsNil: true,
                    error: get(error, 'response.data.message', 'Your old session has expired. Please login again.')
                })
            );
        }
        return Promise.reject(error);
    });
}

/**
 * Eject the interceptor, if any exists.
 */
export const ejectUnauthenticatedRequestReduxStoreIncerceptor = () => {
    if (interceptor) {
        instance.interceptors.response.eject(interceptor);
    }
}