import { all, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { sagaApiRequest } from '../helpers';
import * as viewingTypesAPI from '../../api/viewingTypes';

export function* getAllResourcesRequest() {
    yield takeEvery(actions.GET_ALL_RESOURCES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: viewingTypesAPI.get,
            args: {
                token: payload.token,
                params: {
                    'order_by[column]': 'position',
                    'order_by[direction]': 'asc'
                }
            },
            onSuccess: actions.GET_ALL_RESOURCES_SUCCESS,
            onError: actions.GET_ALL_RESOURCES_ERROR
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(getAllResourcesRequest),
    ]);
}
