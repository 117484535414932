import useGetClientBanner from "../hooks/useGetClientBanner";
import React from "react";
import Marquee from "react-fast-marquee";

const ClientBanner = () => {
    const {isLoading, data, error} = useGetClientBanner();

    return (
        <>
            { !isLoading && !error && data?.clientBanner && (
                <div className="bg-primary py-3 marquee">
                    <Marquee>
                        <div style={{ marginRight: 50 + 'vw'}}>
                            <span>
                                {data.clientBanner.title}
                            </span>

                            <div
                                className="d-inline-flex align-items-center text-white ml-3 mb-0"
                                dangerouslySetInnerHTML={{__html: JSON.parse(data.clientBanner.content_encoded)}}>
                            </div>
                        </div>
                    </Marquee>
                </div>
            )}
        </>
    )
}

export default ClientBanner;