import PropTypes from 'prop-types';
import toastNotification from './toastNotification';

const apiViewingMergeNotification = ({ mergedViewingIds, iconClassName }) => toastNotification({
    iconClassName: iconClassName ? iconClassName : 'fa-compress',
    message: 'Please note this appointment has been merged with: '
        +mergedViewingIds.map((viewingId, idx) => {
            return (
                idx === mergedViewingIds.length - 1
                && mergedViewingIds.length > 1
            )
                ? ` and VV${viewingId}`
                : `VV${viewingId}`;
        }).join(', ')
        +'. Please contact support should you have any questions.',
    type: 'warning',
});

apiViewingMergeNotification.propTypes = {
    resourceDisplayName: PropTypes.string,
};

export default apiViewingMergeNotification;
