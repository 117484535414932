import createActionName from '../createActionName';

export const reducerName = 'auth';

export const CLEAR_METADATA_PROFILE = createActionName(reducerName, 'CLEAR_METADATA_PROFILE');
export const EXTERNAL_FOUR_ZERO_FOUR_RESET = createActionName(reducerName, 'EXTERNAL_FOUR_ZERO_FOUR_RESET');
export const EXTERNAL_FOUR_ZERO_FOUR_SET = createActionName(reducerName, 'EXTERNAL_FOUR_ZERO_FOUR_SET');
export const GET_PROFILE_REQUEST = createActionName(reducerName, 'GET_PROFILE_REQUEST');
export const GET_PROFILE_SUCCESS = createActionName(reducerName, 'GET_PROFILE_SUCCESS');
export const GET_PROFILE_ERROR = createActionName(reducerName, 'GET_PROFILE_ERROR');
export const LOGIN_ERROR_RESET = createActionName(reducerName, 'LOGIN_ERROR_RESET');
export const LOGIN_REQUEST = createActionName(reducerName, 'LOGIN_REQUEST');
export const LOGIN_SUCCESS = createActionName(reducerName, 'LOGIN_SUCCESS');
export const LOGIN_ERROR = createActionName(reducerName, 'LOGIN_ERROR');
export const LOGIN_AS_REQUEST = createActionName(reducerName, 'LOGIN_AS_REQUEST');
export const LOGIN_AS_SUCCESS = createActionName(reducerName, 'LOGIN_AS_SUCCESS');
export const LOGIN_AS_ERROR = createActionName(reducerName, 'LOGIN_AS_ERROR');
export const SIGN_IN_WITH_REAPIT_REQUEST = createActionName(reducerName, 'SIGN_IN_WITH_REAPIT_REQUEST');
export const SIGN_IN_WITH_REAPIT_SUCCESS = createActionName(reducerName, 'SIGN_IN_WITH_REAPIT_SUCCESS');
export const SIGN_IN_WITH_REAPIT_ERROR = createActionName(reducerName, 'SIGN_IN_WITH_REAPIT_ERROR');
export const LOGOUT_REQUEST = createActionName(reducerName, 'LOGOUT_REQUEST');
export const LOGOUT_SUCCESS = createActionName(reducerName, 'LOGOUT_SUCCESS');
export const PASSWORD_RESET_REQUEST = createActionName(reducerName, 'PASSWORD_RESET_REQUEST');
export const PASSWORD_RESET_SUCCESS = createActionName(reducerName, 'PASSWORD_RESET_SUCCESS');
export const PASSWORD_RESET_ERROR = createActionName(reducerName, 'PASSWORD_RESET_ERROR');
export const PASSWORD_RESET_EMAIL_REQUEST = createActionName(reducerName, 'PASSWORD_RESET_EMAIL_REQUEST');
export const PASSWORD_RESET_EMAIL_SUCCESS = createActionName(reducerName, 'PASSWORD_RESET_EMAIL_SUCCESS');
export const PASSWORD_RESET_EMAIL_ERROR = createActionName(reducerName, 'PASSWORD_RESET_EMAIL_ERROR');
export const SET_READ_SUZY_WEBSITE_REQUEST = createActionName(reducerName, 'SET_READ_SUZY_WEBSITE_REQUEST');
export const SET_READ_SUZY_WEBSITE_SUCCESS = createActionName(reducerName, 'SET_READ_SUZY_WEBSITE_SUCCESS');
export const SET_READ_SUZY_WEBSITE_ERROR = createActionName(reducerName, 'SET_READ_SUZY_WEBSITE_ERROR');
export const REGISTER_ERROR_RESET = createActionName(reducerName, 'REGISTER_ERROR_RESET');
export const REGISTER_REQUEST = createActionName(reducerName, 'REGISTER_REQUEST');
export const REGISTER_SUCCESS = createActionName(reducerName, 'REGISTER_SUCCESS');
export const REGISTER_ERROR = createActionName(reducerName, 'REGISTER_ERROR');
export const RESEND_TWO_FACTOR_CODE_ERROR = createActionName(reducerName, 'RESEND_TWO_FACTOR_CODE_ERROR');
export const RESEND_TWO_FACTOR_CODE_REQUEST = createActionName(reducerName, 'RESEND_TWO_FACTOR_CODE_REQUEST');
export const RESEND_TWO_FACTOR_CODE_SUCCESS = createActionName(reducerName, 'RESEND_TWO_FACTOR_CODE_SUCCESS');
export const VERIFY_TWO_FACTOR_CODE_REQUEST = createActionName(reducerName, 'VERIFY_TWO_FACTOR_CODE_REQUEST');
export const VERIFY_TWO_FACTOR_CODE_SUCCESS = createActionName(reducerName, 'VERIFY_TWO_FACTOR_CODE_SUCCESS');
export const VERIFY_TWO_FACTOR_CODE_ERROR = createActionName(reducerName, 'VERIFY_TWO_FACTOR_CODE_ERROR');
export const REPLACE_TOKEN = createActionName(reducerName, 'REPLACE_TOKEN');
export const UPDATE_PROFILE_REQUEST = createActionName(reducerName, 'UPDATE_PROFILE_REQUEST');
export const UPDATE_PROFILE_SUCCESS = createActionName(reducerName, 'UPDATE_PROFILE_SUCCESS');
export const UPDATE_PROFILE_ERROR = createActionName(reducerName, 'UPDATE_PROFILE_ERROR');
export const UPLOAD_PROFILE_PICTURE_REQUEST = createActionName(reducerName, 'UPLOAD_PROFILE_PICTURE_REQUEST');
export const UPLOAD_PROFILE_PICTURE_SUCCESS = createActionName(reducerName, 'UPLOAD_PROFILE_PICTURE_SUCCESS');
export const UPLOAD_PROFILE_PICTURE_ERROR = createActionName(reducerName, 'UPLOAD_PROFILE_PICTURE_ERROR');

// actions
const actions = {
    RESEND_TWO_FACTOR_CODE_ERROR,
    RESEND_TWO_FACTOR_CODE_REQUEST,
    RESEND_TWO_FACTOR_CODE_SUCCESS,
    VERIFY_TWO_FACTOR_CODE_REQUEST,
    VERIFY_TWO_FACTOR_CODE_SUCCESS,
    VERIFY_TWO_FACTOR_CODE_ERROR,
    CLEAR_METADATA_PROFILE,
    EXTERNAL_FOUR_ZERO_FOUR_RESET,
    EXTERNAL_FOUR_ZERO_FOUR_SET,
    GET_PROFILE_REQUEST,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_ERROR,
    LOGIN_ERROR_RESET,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGIN_AS_REQUEST,
    LOGIN_AS_SUCCESS,
    LOGIN_AS_ERROR,
    SIGN_IN_WITH_REAPIT_REQUEST,
    SIGN_IN_WITH_REAPIT_SUCCESS,
    SIGN_IN_WITH_REAPIT_ERROR,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_ERROR,
    PASSWORD_RESET_EMAIL_REQUEST,
    PASSWORD_RESET_EMAIL_SUCCESS,
    PASSWORD_RESET_EMAIL_ERROR,
    SET_READ_SUZY_WEBSITE_REQUEST,
    SET_READ_SUZY_WEBSITE_SUCCESS,
    SET_READ_SUZY_WEBSITE_ERROR,
    REGISTER_ERROR_RESET,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    REPLACE_TOKEN,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_ERROR,
    UPLOAD_PROFILE_PICTURE_REQUEST,
    UPLOAD_PROFILE_PICTURE_SUCCESS,
    UPLOAD_PROFILE_PICTURE_ERROR,
};

// action creators
export const clearMetadataProfile = payload => ({
    type: actions.CLEAR_METADATA_PROFILE
});

export const externalFourZeroFourReset = payload => ({
    type: actions.EXTERNAL_FOUR_ZERO_FOUR_RESET,
    payload
});

export const externalFourZeroFourSet = payload => ({
    type: actions.EXTERNAL_FOUR_ZERO_FOUR_SET,
    payload
});

export const getProfile = payload => ({
    type: actions.GET_PROFILE_REQUEST,
    payload
});

export const login = payload => ({
    type: actions.LOGIN_REQUEST,
    payload
});

export const verifyTwoFactorCode = payload => ({
    type: actions.VERIFY_TWO_FACTOR_CODE_REQUEST,
    payload
});

export const resendTwoFactorCode = payload => ({
    type: actions.RESEND_TWO_FACTOR_CODE_REQUEST,
    payload
});

export const loginAs = payload => ({
    type: actions.LOGIN_AS_REQUEST,
    payload
});

export const signInWithReapit = payload => ({
    type: actions.SIGN_IN_WITH_REAPIT_REQUEST,
    payload
});

export const logout = payload => ({
    type: actions.LOGOUT_REQUEST,
    payload
});

export const passwordReset = payload => ({
    type: actions.PASSWORD_RESET_REQUEST,
    payload
});

export const passwordResetEmail = payload => ({
    type: actions.PASSWORD_RESET_EMAIL_REQUEST,
    payload
});

export const setReadSuzyWebsite = payload => ({
    type: actions.SET_READ_SUZY_WEBSITE_REQUEST,
    payload
})

export const register = payload => ({
    type: actions.REGISTER_REQUEST,
    payload
});

export const replaceToken = payload => ({
    type: actions.REPLACE_TOKEN,
    payload
})

export const resetLoginError = payload => ({
    type: actions.LOGIN_ERROR_RESET
});

export const resetRegisterError = payload => ({
    type: actions.REGISTER_ERROR_RESET
});

export const updateProfile = payload => ({
    type: actions.UPDATE_PROFILE_REQUEST,
    payload
});

export const uploadProfilePicture = payload => ({
    type: actions.UPLOAD_PROFILE_PICTURE_REQUEST,
    payload
});

export default actions;
