import actions from './actions';
import baseReducer from '../base/reducer';

const initial_state = {
    created: false,
    error: null,
    fetching_resources: false,
    resource: null,
    resources: [],
    total: 0,
};

const reducer = (state = initial_state, action) => {
    switch(action.type) {
        case actions.CLEAR_METADATA_RESOURCE_CREATE:
            return {
                ...state,
                created: false,
                error: null,
                resource: null,
            };
        case actions.CHECK_RESOURCE_REQUEST:
            return {
                ...state,
                error: null,
                resource: null,
            };
        case actions.CHECK_RESOURCE_SUCCESS:
            return {
                ...state,
                error: null,
                resource: action.payload.resource,
            };
        case actions.CHECK_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
                resource: null,
            };
        default:
            return baseReducer(actions)(state, action);
    }
};

export default reducer;
