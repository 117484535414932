import createActionName from '../createActionName';
export const reducerName = 'feedbackKeyHolding';

export const GET_FEEDBACK_KEY_HOLDING_REQUEST = createActionName(reducerName, 'GET_FEEDBACK_KEY_HOLDING_REQUEST');
export const GET_FEEDBACK_KEY_HOLDING_SUCCESS = createActionName(reducerName, 'GET_FEEDBACK_KEY_HOLDING_SUCCESS');
export const GET_FEEDBACK_KEY_HOLDING_ERROR = createActionName(reducerName, 'GET_FEEDBACK_KEY_HOLDING_ERROR');

// actions
const actions = {
	GET_FEEDBACK_KEY_HOLDING_REQUEST,
	GET_FEEDBACK_KEY_HOLDING_SUCCESS,
	GET_FEEDBACK_KEY_HOLDING_ERROR,
};

export const getFeedbackKeyHolding = (payload) => ({
	type: actions.GET_FEEDBACK_KEY_HOLDING_REQUEST,
	payload,
});

export default actions;
