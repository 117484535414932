import * as documentsAPI from '../index';
import {useQuery} from '@tanstack/react-query'

const documentsQueryKey = params => ['documents', 'all', params];

export const useDocuments = (params, reactQueryOptions) => {
  const {
    data,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(
    documentsQueryKey(params),
    () => new Promise((resolve, reject) => {
      documentsAPI.get(params)
        .then(res => resolve(res.data.resources))
        .catch(reject);
    }),
    reactQueryOptions
  );

  return {
    documents: data,
    isLoading,
    isError,
    error,
    refetch,
  }
}