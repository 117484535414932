import { all, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { sagaApiRequest } from '../helpers';
import * as appointmentsAPI from '../../api/appointments';

export function* findResourceRequest() {
    yield takeEvery(actions.FIND_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.find,
            args: [payload.data.id, payload.data.token],
            onSuccess: actions.FIND_RESOURCE_SUCCESS,
            onError: actions.FIND_RESOURCE_ERROR
        });
    });
}

export function* getPaginatedResourcesRequest() {
    yield takeEvery(actions.GET_PAGINATED_RESOURCES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.paginateOutstandingStarRatingAppointments,
            args: payload.data,
            onSuccess: actions.GET_PAGINATED_RESOURCES_SUCCESS,
            onError: actions.GET_PAGINATED_RESOURCES_ERROR
        });
    });
}

export function* rateResourceRequest() {
    yield takeEvery(actions.RATE_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.rate,
            args: payload.data,
            onSuccess: actions.RATE_RESOURCE_SUCCESS,
            onError: actions.RATE_RESOURCE_ERROR
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(findResourceRequest),
        fork(getPaginatedResourcesRequest),
        fork(rateResourceRequest),
    ]);
}
