import createActionName from '../createActionName';
import { reducerName } from './variables';

export const CHANGE_PAGE_RESOURCES = createActionName(reducerName, 'CHANGE_PAGE_RESOURCES');
export const GET_PAGINATED_RESOURCES_REQUEST = createActionName(reducerName, 'GET_PAGINATED_RESOURCES_REQUEST');
export const GET_PAGINATED_RESOURCES_SUCCESS = createActionName(reducerName, 'GET_PAGINATED_RESOURCES_SUCCESS');
export const GET_PAGINATED_RESOURCES_ERROR = createActionName(reducerName, 'GET_PAGINATED_RESOURCES_ERROR');
export const SET_ERROR = createActionName(reducerName, 'SET_ERROR');
export const CLEAR_METADATA_RESOURCES = createActionName(reducerName, 'CLEAR_METADATA_RESOURCES');

// actions
const actions = {
	CHANGE_PAGE_RESOURCES,
	GET_PAGINATED_RESOURCES_REQUEST,
	GET_PAGINATED_RESOURCES_SUCCESS,
	GET_PAGINATED_RESOURCES_ERROR,
    SET_ERROR,
	CLEAR_METADATA_RESOURCES,
};

// action creators

export const clearMetadataResources = payload => ({
	type: actions.CLEAR_METADATA_RESOURCES,
	payload,
});

export const changePageResources = payload => ({
    type: actions.CHANGE_PAGE_RESOURCES,
	payload,
});

export const getPaginatedResources = payload => ({
    type: actions.GET_PAGINATED_RESOURCES_REQUEST,
    payload,
});

export default actions;
