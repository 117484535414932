import React from 'react';
import PropTypes from 'prop-types';
import { Route, Link, useLocation, matchPath } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

const Breadcrumbs = ({
    appRoutes,
    rightContent
}) => {
    const location = useLocation();
    const paths = getPaths(location.pathname);

    return (
        <div className='d-flex align-items-center justify-content-between mb-4 bg-white border-bottom'>
            <div className='flex-grow-1'>
                <Breadcrumb listClassName='bg-transparent border-bottom-0 mb-0'>
                    {paths.map((path, i) => (
                        <Route
                            key={i}
                            path={path}
                            render={({match}) => {
                                const routeName = findRouteName(match.url, appRoutes);
                                
                                if (!routeName) {
                                    return null;
                                }

                                if (match.isExact) {
                                    return (
                                        <BreadcrumbItem active>
                                            {routeName}
                                        </BreadcrumbItem>
                                    )
                                }

                                return (
                                    <BreadcrumbItem active={match.isExact}>
                                        <Link to={match.url}>
                                            {routeName}
                                        </Link>
                                    </BreadcrumbItem>
                                )
                            }}
                        />
                    ))}
                </Breadcrumb>
            </div>
            {rightContent && (
                <div className='pr-3'>
                    {rightContent}
                </div>
            )}
        </div>
    )
}

Breadcrumbs.propTypes = {
    appRoutes: PropTypes.arrayOf(PropTypes.shape({
        path: PropTypes.string,
        exact: PropTypes.bool,
    })).isRequired,
    rightContent: PropTypes.node,
}

const getPaths = pathname => {
    var paths = ['/'];
    if (pathname === '/') return paths;
    pathname.split('/').reduce(function (prev, curr) {
      var currPath = prev + "/" + curr;
      paths.push(currPath);
      return currPath;
    });
    return paths;
}

const findRouteName = (url, routes) => {
    const r = routes.find(route => {
      return matchPath(url, {
        path: route.path,
        exact: route.exact
      });
    });
    return r && r.name ? r.name : null;
}

export default Breadcrumbs;