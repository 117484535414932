import * as returnArrangementsApi from '../index';
import {useQuery} from '@tanstack/react-query'

const allReturnArrangementsQueryKey = token => ['return-arrangements', 'all', token];

export const useReturnArrangements = (
  token,
  options = {
    staleTime: 5 * 60 * 1000 // 5 minutes
  }
) => {
  const {
    data,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(
    allReturnArrangementsQueryKey(token),
    () => new Promise((resolve, reject) => {
      returnArrangementsApi.get({token})
        .then(res => resolve(res.data.resources))
        .catch(reject);
    }),
    options
  );

  return {
    returnArrangements: data,
    isLoading,
    isError,
    error,
    refetch
  }
}