import PropTypes from 'prop-types';
import toastNotification from './toastNotification';

export const feedbackQuestionUpdateSuccessNotification = ({ resourceDisplayName }) => toastNotification({
    iconClassName: 'fa-warning',
    message: 'Please note, this question will not appear in Viewber feedback until approved by Admin.',
    type: 'warning',
});

feedbackQuestionUpdateSuccessNotification.propTypes = {
    resourceDisplayName: PropTypes.string
};

export default feedbackQuestionUpdateSuccessNotification;
