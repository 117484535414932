import React from 'react';
import PropTypes from 'prop-types';
import FormText from './FormText';
import {Input, Label, FormFeedback} from 'reactstrap';

const Select = ({
    id,
    name,
    value,
    className,
    label,
    labelClassName,
    valid,
    invalid,
    disabled,
    helpText,
    error,
    children,
    onChange,
    onBlur
}) => {
    const helpTextId = id ? `${id}-helpText` : undefined;

    return (
        <React.Fragment>
            {label && <Label for={id} className={labelClassName}>{label}</Label>}
            <Input
                id={id}
                data-testid={id}
                type='select'
                name={name}
                className={className}
                value={value}
                valid={valid}
                invalid={invalid}
                disabled={disabled}
                aria-describedby={helpTextId}
                onChange={onChange}
                onBlur={onBlur}
            >
                {children}
            </Input>
            {error &&
                (Array.isArray(error)
                    ? error.map((e,i) => <FormFeedback key={i}>{e}</FormFeedback>)
                    : <FormFeedback>{error}</FormFeedback>
                )
            }
            {helpText &&
                <FormText id={helpTextId}>
                    {helpText}
                </FormText>
            }
        </React.Fragment>
    )
}

Select.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    valid: PropTypes.bool,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
    helpText: PropTypes.string,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    children: PropTypes.node,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
}

export default Select;