import createActionName from '../createActionName';
import { reducerName } from './variables';

export const CLEAR_METADATA_RESOURCE_CREATE = createActionName(reducerName, 'CLEAR_METADATA_RESOURCE_CREATE');
export const CHECK_RESOURCE_REQUEST = createActionName(reducerName, 'CHECK_RESOURCE_REQUEST');
export const CHECK_RESOURCE_SUCCESS = createActionName(reducerName, 'CHECK_RESOURCE_SUCCESS');
export const CHECK_RESOURCE_ERROR = createActionName(reducerName, 'CHECK_RESOURCE_ERROR');

// actions
const actions = {
    CLEAR_METADATA_RESOURCE_CREATE,
  	CHECK_RESOURCE_REQUEST,
  	CHECK_RESOURCE_SUCCESS,
  	CHECK_RESOURCE_ERROR,
};

// action creators
export const clearMetadataResourceCreate = payload => ({
    type: actions.CLEAR_METADATA_RESOURCE_CREATE,
});

export const checkResource = payload => ({
    type: actions.CHECK_RESOURCE_REQUEST,
    payload,
});

export default actions;
