import toastNotification from './toastNotification';
import apiResourceCreateSuccessNotification from './apiResourceCreateSuccessNotification';
import apiResourceDestroySuccessNotification from './apiResourceDestroySuccessNotification';
import apiResourceRateSuccessNotification from './apiResourceRateSuccessNotification';
import apiResourceUpdateSuccessNotification from './apiResourceUpdateSuccessNotification';
import apiResourceUpdateFailureNotification from './apiResourceUpdateFailureNotification'
import apiValidationErrorsNotification from './apiValidationErrorsNotification';
import apiViewingMergeNotification from './apiViewingMergeNotification';
import feedbackQuestionCreateSuccessNotification from './feedbackQuestionCreateSuccessNotification';
import feedbackQuestionUpdateSuccessNotification from './feedbackQuestionUpdateSuccessNotification';
import noInternetConnectionNotification from './noInternetConnectionNotification';
import redirectPaymentUrlNotification from './redirectPaymentUrlNotification';
import smsMessageUpdateSuccessNotification from './smsMessageUpdateSuccessNotification';

export {
    apiResourceCreateSuccessNotification,
    apiResourceDestroySuccessNotification,
    apiResourceRateSuccessNotification,
    apiResourceUpdateSuccessNotification,
    apiResourceUpdateFailureNotification,
    apiValidationErrorsNotification,
    apiViewingMergeNotification,
    feedbackQuestionCreateSuccessNotification,
    feedbackQuestionUpdateSuccessNotification,
    noInternetConnectionNotification,
    redirectPaymentUrlNotification,
    smsMessageUpdateSuccessNotification,
};

export default toastNotification;
