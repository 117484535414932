import apiRequest from '../apiRequest';

export const get = async ({ token }) =>
    apiRequest('documents', {
        method: 'GET',
        token,
    });

export const toRead = ({ token }) =>
    apiRequest('documents/to-read', {
        method: 'GET',
        token
    });