import * as viewingsAPI from '../index';
import {useQuery} from '@tanstack/react-query'

const paginatedViewingsQueryKey = params => ['viewings', 'paginate', params];

export const usePaginatedViewings = (token, params, reactQueryOptions) => {
  const {
    data,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(
    paginatedViewingsQueryKey(params),
    () => new Promise((resolve, reject) => {
      viewingsAPI.paginate({...params, token})
        .then(res => resolve(res.data.data))
        .catch(reject);
    }),
    reactQueryOptions
  );

  return {
    viewings: data,
    isLoading,
    isError,
    error,
    refetch,
  }
}