import actions from './actions';
import baseReducer from '../base/reducer';

const initial_state = {
    created: false,
    destroyed: false,
    current_page: 1,
    error: null,
    fetching_resources: false,
    resource: null,
    resources: [],
    paginated_resources: {
        1: []
    },
    total: 0,
    updated: false,
};

const reducer = (state = initial_state, action) => {
    switch(action.type) {
        case actions.GET_ALL_RESOURCES_REQUEST: {
            return {
                ...state,
                error: null,
                fetching_resources: true,
                resources: [],
                total: 0,
            };
        }
        case actions.GET_ALL_RESOURCES_SUCCESS: {
            const { resources } = action.payload;
            return {
                ...state,
                error: null,
                fetching_resources: false,
                last_fetched: (new Date()).getTime(),
                resources: resources,
            };
        }
        case actions.GET_ALL_RESOURCES_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_resources: false,
            };
        default:
            return baseReducer(actions)(state, action);
    }
};

export default reducer;
