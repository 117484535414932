import React from 'react';
import { Col, Row } from 'reactstrap';
import Spinner from './Spinner';

const SpinnerRow = () => (
    <Row className='spinner-loader' style={{'height': '300px'}}>
        <Col className="col-md-12 text-center" style={{'margin': 'auto'}}>
            <Spinner size='5x' />
        </Col>
    </Row>
);

export default SpinnerRow;
