import isNil from 'lodash/isNil';
import apiRequest from '../apiRequest';

export const paginate = async ({token, page, pageSize, q, user_id, sort_name, sort_direction}) =>
    apiRequest(`users/${user_id}/actions`, {
        token,
        params: {
            page,
            page_size: pageSize,
            q: !isNil(q) ? q : undefined,
            sort_name,
            sort_direction
        }
    });