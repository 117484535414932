import actions from './actions';
import baseReducer from '../base/reducer';

const initial_state = {
    created: false,
    destroyed: false,
    current_page: 1,
    error: null,
    fetching_resources: false,
    resource: null,
    resources: [],
    paginated_resources: {
        1: []
    },
    rated: false,
    total: 0,
    updated: false,
    viewing_pack_credit: null,
};

const reducer = (state = initial_state, action) => {
    switch(action.type) {
        case actions.CHANGE_PAGE_RESOURCES:
            return {
                ...state,
                current_page: action.payload.data.page
            };
        case actions.CLEAR_METADATA_RESOURCES: {
            const { data } = action.payload;
            return {
                ...state,
                current_page: 1,
                destroyed: false,
                error: null,
                fetching_resources: false,
                resources: [],
                paginated_resources: (
                        data.query !== ''
                        && data.query !== null
                        && typeof data.query !== 'undefined'
                    )
                    // If I've searched before,
                    // Clear the resources so on next reload
                    // A re-fetch will be needed
                    ? {
                        1: []
                    }
                    : {
                        ...state.paginated_resources
                    }
            };
        }
        case actions.CLEAR_METADATA_RESOURCE_EDIT: {
            return {
                ...state,
                created: false,
                destroyed: false,
                error: null,
                resource: null,
                rated: false,
                updated: false,
            };
        }
        case actions.GET_PAGINATED_RESOURCES_REQUEST: {
            const { data } = action.payload;
            return {
                ...state,
                error: null,
                fetching_resources: true,
                current_page: data.page,
                paginated_resources: {
                    ...state.paginated_resources,
                    [data.page]: []
                },
                total: 0,
            };
        }
        case actions.GET_PAGINATED_RESOURCES_SUCCESS: {
            const { data, total, current_page } = action.payload;
            return {
                ...state,
                current_page: current_page,
                error: null,
                fetching_resources: false,
                paginated_resources: {
                    ...state.paginated_resources,
                    [current_page]: data
                },
                total: total,
            };
        }
        case actions.GET_PAGINATED_RESOURCES_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_resources: false,
                total: 0
            };
        case actions.RATE_RESOURCE_REQUEST:
            return {
                ...state,
                error: null,
                rated: false,
            };
        case actions.RATE_RESOURCE_SUCCESS: {
            const { resource } = action.payload;
            return {
                ...state,
                error: null,
                rated: true,
                resource: resource,
            };
        }
        case actions.RATE_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
                rated: false,
            };
        default:
            return baseReducer(actions)(state, action);
    }
};

export default reducer;
