import React from 'react';
import PropTypes from 'prop-types';
import { RollbarContext } from '@rollbar/react';

// This ErrorReportingContext takes a string of
// context and passes it to the context of the
// error reporting provider we are currently using,
// if any, so we can easily switch provider.

const ErrorReportingContext = ({
    context,
    children,
}) => {
    return (
        <RollbarContext context={context}>
            {children}
        </RollbarContext>
    );
}

ErrorReportingContext.propTypes = {
    context: PropTypes.string,
    children: PropTypes.node,
}

export default ErrorReportingContext;