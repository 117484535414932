import * as viewingDateTypesAPI from '../index';
import {useQuery} from '@tanstack/react-query'

const allViewingDateTypesQueryKey = token => ['viewing-date-types', 'all', token];

export const useViewingDateTypes = token => {
  const {
    data,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(
    allViewingDateTypesQueryKey(token),
    () => new Promise((resolve, reject) => {
      viewingDateTypesAPI.get({token})
        .then(res => resolve(res.data.resources))
        .catch(reject);
    }),
    {
      staleTime: 5 * 60 * 1000
    }
  );

  return {
    viewingDateTypes: data,
    isLoading,
    isError,
    error,
    refetch
  }
}