import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import throttle from 'lodash/throttle';
import rootReducer from './reducers';
import sagaRegistry from './sagaRegistry';
import registerDefaultSagas from './sagas';
import { loadState, saveState } from './localStorage';
import { configureStore as toolkitConfigureStore } from '@reduxjs/toolkit';
import { setupUnauthenticatedRequestReduxStoreIncerceptor } from '../api/axios/interceptors';

export const configureStore = () => {
    let preloadedState = undefined;

    // Create the saga middleware.
    const sagaMiddleware = createSagaMiddleware();

    // Fetch the preloaded state, if any.
    if (process.env.REACT_APP_STATE_DRIVER === "localStorage") {
        preloadedState = loadState();
    }

    // Register the default sagas.
    registerDefaultSagas();

    // Create the store.
    const store = toolkitConfigureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sagaMiddleware),
        preloadedState
    });

    // Setup the axios interceptors.
    setupUnauthenticatedRequestReduxStoreIncerceptor(store);

    // Periodically save the state to local storage if enabled.
    if (process.env.REACT_APP_STATE_DRIVER === "localStorage") {
        store.subscribe(throttle(() => {
            saveState(store.getState());
        }, 1000));
    }
    
    // We set an event listener for the saga registry
    // So that whenever a new saga gets added
    // We replace the sagas with the new ones
    sagaRegistry.setChangeListener((sagas) => {
        function* allSagas(getState) {
            yield all(sagas);
        }
        sagaMiddleware.run(allSagas);
    });

    // Create the all sagas function.
    function* allSagas(getState) {
        yield all(sagaRegistry.getSagas());
    }

    // Run the all sagas function.
    sagaMiddleware.run(allSagas);

    // Return the store.
    return store;
}

const store = configureStore();

export default store;
