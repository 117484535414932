import { all, takeEvery, put, call, fork } from 'redux-saga/effects';
import actions from './actions';
import { sagaApiRequest } from '../helpers';
import * as discountsAPI from '../../api/discounts';

export function* checkResourceRequest() {
    yield takeEvery(actions.CHECK_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: discountsAPI.checkDiscount,
            args: {
                token: payload.data.token,
                discountCode: payload.data.discount_code,
                propertyId: payload.data.properties_id,
                viewingLengthId: payload.data.viewing_length_id,
                preferredDate: payload.data.preferred_date,
            },
            onSuccess: actions.CHECK_RESOURCE_SUCCESS,
            onError: actions.CHECK_RESOURCE_ERROR
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(checkResourceRequest),
    ]);
}
