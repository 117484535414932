import createActionName from '../createActionName';
import { reducerName } from './variables';

export const CLEAR_METADATA_RESOURCES = createActionName(reducerName, 'CLEAR_METADATA_RESOURCES');
export const SEARCH_POSTCODE_REQUEST = createActionName(reducerName, 'SEARCH_POSTCODE_REQUEST');
export const SEARCH_POSTCODE_SUCCESS = createActionName(reducerName, 'SEARCH_POSTCODE_SUCCESS');
export const SEARCH_POSTCODE_ERROR = createActionName(reducerName, 'SEARCH_POSTCODE_ERROR');

// actions
const actions = {
	CLEAR_METADATA_RESOURCES,
	SEARCH_POSTCODE_REQUEST,
	SEARCH_POSTCODE_SUCCESS,
	SEARCH_POSTCODE_ERROR,
};

// action creators
export const clearMetadataResources = payload => ({
    type: actions.CLEAR_METADATA_RESOURCES,
    payload,
});

export const searchPostcode = payload => ({
    type: actions.SEARCH_POSTCODE_REQUEST,
	payload,
});

export default actions;
