import { all, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { sagaApiRequest } from '../helpers';
import * as feedbackQuestionsAPI from '../../api/feedbackQuestions';

export function* getAllResourcesRequest() {
    yield takeEvery(actions.GET_ALL_RESOURCES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: feedbackQuestionsAPI.get,
            args: payload.data,
            onSuccess: actions.GET_ALL_RESOURCES_SUCCESS,
            onError: actions.GET_ALL_RESOURCES_ERROR
        });
    });
}

export function* createResourceRequest() {
    yield takeEvery(actions.CREATE_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: feedbackQuestionsAPI.create,
            args: payload.data,
            onSuccess: actions.CREATE_RESOURCE_SUCCESS,
            onError: actions.CREATE_RESOURCE_ERROR
        });
    });
}

export function* destroyResourceRequest() {
    yield takeEvery(actions.DESTROY_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: feedbackQuestionsAPI.destroy,
            args: payload.data,
            onSuccess: actions.DESTROY_RESOURCE_SUCCESS,
            onError: actions.DESTROY_RESOURCE_ERROR
        });
    });
}

export function* findResourceRequest() {
    yield takeEvery(actions.FIND_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: feedbackQuestionsAPI.find,
            args: payload.data,
            onSuccess: actions.FIND_RESOURCE_SUCCESS,
            onError: actions.FIND_RESOURCE_ERROR
        });
    });
}

export function* updateResourceRequest() {
    yield takeEvery(actions.UPDATE_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: feedbackQuestionsAPI.update,
            args: payload.data,
            onSuccess: actions.UPDATE_RESOURCE_SUCCESS,
            onError: actions.UPDATE_RESOURCE_ERROR
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(createResourceRequest),
        fork(destroyResourceRequest),
        fork(findResourceRequest),
        fork(getAllResourcesRequest),
        fork(updateResourceRequest),
    ]);
}
