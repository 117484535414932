import apiRequest from '../apiRequest';

export const get = ({ token, ...rest }) => 
	apiRequest('viewing-lengths', {
		token,
		params: rest,
	});

export const addonsSearch = ({ token }) => 
	apiRequest('/viewing-lengths/add-ons/search', {
		token
	});

export const getAccessArrangements = (viewingLengthId, token) => 
	apiRequest(`viewing-lengths/${viewingLengthId}/access-arrangements`, {
		token
	});

export const getReturnArrangements = (viewingLengthId, token) => 
	apiRequest(`viewing-lengths/${viewingLengthId}/return-arrangements`, {
		token
	});