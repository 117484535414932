import * as accessArrangementsApi from '../index';
import {useQuery} from '@tanstack/react-query'

const allAccessArrangementsQueryKey = token => ['access-arrangements', 'all', token];

export const useAccessArrangements = (
  token,
  options = {
    staleTime: 5 * 60 * 1000 // 5 minutes
  }
) => {
  const {
    data,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(
    allAccessArrangementsQueryKey(token),
    () => new Promise((resolve, reject) => {
        accessArrangementsApi.get(token)
            .then(res => resolve(res.data.resources))
            .catch(reject);
    }),
    options
  );

  return {
    accessArrangements: data,
    isLoading,
    isError,
    error,
    refetch
  }
}