import * as documentCategoriesAPI from '../index';
import {useQuery} from '@tanstack/react-query'

const documentCategoriesLatestDocumentQueryKey = params => ['document-categories', 'show', 'latest-document', 'show', params];

export const useDocumentCategoryLatestDocument = (params, reactQueryOptions) => {
  const {
    data,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(
    documentCategoriesLatestDocumentQueryKey(params),
    () => new Promise((resolve, reject) => {
      documentCategoriesAPI.latestDocument(params)
        .then(res => resolve(res.data.data))
        .catch(reject);
    }),
    reactQueryOptions
  );

  return {
    document: data,
    isLoading,
    isError,
    error,
    refetch,
  }
}