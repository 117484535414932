import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Spinner = ({
    size,
    className,
}) => (
    <i className={classnames({
        'fa': true,
        'fa-spinner': true,
        'fa-spin': true,
        'fa-2x': size === '2x',
        'fa-3x': size === '3x',
        'fa-4x': size === '4x',
        'fa-5x': size === '5x',
    }, className)} />
);

Spinner.propTypes = {
    size: PropTypes.oneOf([
        '2x',
        '3x',
        '4x',
        '5x',
    ]),
    className: PropTypes.string,
}

export default Spinner;
