import React from 'react';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Checkbox } from '../Form';

const FormikCheckbox = ({
    id,
    name,
    label,
    disabled,
    helpText
}) => (
    <Field name={name}>
        {({
            field, // { name, value, onChange, onBlur }
            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
        }) => {
            return (
                <Checkbox
                    id={id}
                    name={field.name}
                    label={label}
                    checked={field.checked || field.value === true}
                    helpText={helpText}
                    invalid={meta.touched && !isNil(meta.error)}
                    disabled={disabled}
                    error={meta.error}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                />
            );
        }}
    </Field>
)

FormikCheckbox.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    helpText: PropTypes.string,
}

export default FormikCheckbox;