import React from 'react';
import PropTypes from 'prop-types';
import {Alert as BaseAlert} from 'reactstrap';

const Alert = ({
    color,
    children
}) => (
    <BaseAlert color={color}>
        {children}
    </BaseAlert>
);

Alert.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', 'success', 'info', 'warning', 'danger']),
    children: PropTypes.node
}

export default Alert;