import React from 'react';
import { isNil } from 'lodash';

const {
    REACT_APP_DOCUMENT_NOTIFICATION_TARGET_TYPE_ID,
	REACT_APP_VIEWING_NOTIFICATION_TARGET_TYPE_ID,
	REACT_APP_VIEWING_QUERY_TARGET_SUB_TYPE_ID
} = process.env;

export const getNotificationIconClassName = notification => {
    if(
        notification
        && notification.target_type
    ) {
        const { target_type, target_sub_type } = notification;

        if(target_type.id === parseInt(REACT_APP_VIEWING_NOTIFICATION_TARGET_TYPE_ID, 10)) {
            return 'fa-clock-o';
        }

        // Viewing - In Query
        if(
           target_type.id === parseInt(REACT_APP_VIEWING_NOTIFICATION_TARGET_TYPE_ID, 10)
            || (
                !isNil(target_sub_type)
                && target_sub_type.id === parseInt(REACT_APP_VIEWING_QUERY_TARGET_SUB_TYPE_ID, 10)
            )
        ) {
            return 'fa-clock-o';
        }

        // Document
        if(
            target_type.id === parseInt(REACT_APP_DOCUMENT_NOTIFICATION_TARGET_TYPE_ID, 10)
            && !target_sub_type
        ) {
            return 'fa-file';
        }
    }

    return null;
};

export const getNotificationTitle = notification => {
    if(
        !notification
        || !notification.target
        || !notification.target_type
    ) {
        return null;
    }

    const { target_type, target_sub_type } = notification;

    if(notification.decoded_payload && notification.decoded_payload.title) {
        return notification.decoded_payload.title;
    }

     // Viewing notification
    if(
        target_type.id === parseInt(REACT_APP_VIEWING_NOTIFICATION_TARGET_TYPE_ID, 10)
        || (
            !isNil(target_sub_type)
            && target_sub_type.id === parseInt(REACT_APP_VIEWING_QUERY_TARGET_SUB_TYPE_ID, 10)
        )
    ) {
        return 'Viewing Query';
    }

    // Document notification
    if(
        target_type.id === parseInt(REACT_APP_DOCUMENT_NOTIFICATION_TARGET_TYPE_ID, 10)
        && !target_sub_type
    ) {
        return 'New Document';
    }

    return null;
};

export const getNotificationText = notification => {
    if(
        !notification
        || !notification.target
        || !notification.target_type
    ) {
        return null;
    }

    const { target, target_type, target_sub_type } = notification;

    if(notification.decoded_payload && notification.decoded_payload.content) {
        return notification.decoded_payload.content;
    }


    // Viewing Query notification
    if(
        target_type.id === parseInt(REACT_APP_VIEWING_NOTIFICATION_TARGET_TYPE_ID, 10)
        || (
            !isNil(target_sub_type)
            && target_sub_type.id === parseInt(REACT_APP_VIEWING_QUERY_TARGET_SUB_TYPE_ID, 10)
        )
    ) {
        if(notification.decoded_payload) {
	        return (
		        <>
    		        <span style={{display:'block'}}>{notification.decoded_payload.title}</span>
    		        <span style={{display:'block'}}>{notification.decoded_payload.content}</span>
				</>
	        );
        }

        return `Query for VV${notification.target.id}`;

    }

    // Document notification
    if(
        target_type.id === parseInt(REACT_APP_DOCUMENT_NOTIFICATION_TARGET_TYPE_ID, 10)
        && !target_sub_type
    ) {

        const { document_category } = target;

        if(!document_category) {
            return null;
        }

        return `A new version of the ${document_category.name} is available`;

    }

    if(target_sub_type?.id === 14 && target?.request_id ) {
        return (<strong>{target_sub_type.name} - VV{target.request_id}</strong>);
    }

    return null;
};
