import actions from './actions';
import baseReducer from '../base/reducer';

const initial_state = {
    error: null,
    fetching_resources: false,
    resources: [],
};

const reducer = (state = initial_state, action) => {
    switch(action.type) {
        default:
            return baseReducer(actions)(state, action);
    }
};

export default reducer;
