import React from 'react';
import PropTypes from 'prop-types';
import FormText from './FormText';
import {Input, Label, FormFeedback} from 'reactstrap';

const TextInput = ({
    id,
    label,
    labelClassName,
    error,
    helpText,
    ...rest
}) => {
    const helpTextId = id ? `${id}-helpText` : undefined;

    return (
        <React.Fragment>
            {label && <Label for={id} className={labelClassName}>{label}</Label>}
            <Input
                id={id}
                type='text'
                {...rest}
            />
            {error &&
                (Array.isArray(error)
                    ? error.map((e,i) => <FormFeedback key={i}>{e}</FormFeedback>)
                    : <FormFeedback>{error}</FormFeedback>
                )
            }
            {helpText &&
                <FormText id={helpTextId}>
                    {helpText}
                </FormText>
            }
        </React.Fragment>
    )
}

TextInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    placeholder: PropTypes.string,
    valid: PropTypes.bool,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
    helpText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    autoComplete: PropTypes.string,
    ariaAutoComplete: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
}

export default TextInput;