import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import SpinnerRow from '../../components/SpinnerRow';
import { getApiErrorMessages } from '../../helpers/apiMessages';
import { getProfile, replaceToken, logout } from '../../redux/auth/actions';

class Auth extends Component {
    state = {
        redirectLogin: false,
        redirectDashboard: false,
    };

    componentDidMount() {
        const {logout, location} = this.props;

        // We get the URI converted into a key-value object
        const uriObj = queryString.parse(location.search);

        if (Object.keys(uriObj).indexOf('t') > -1) {
            // If the keys contain 't' we log out user
            logout({skipApi: true});
        } else {
            // If not present, we redirect straight to login
            this.setState({
                redirectLogin: true,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            errors,
            getProfile,
            location,
            logged_out,
            replaceToken,
            user,
        } = this.props;

        const uriObj = queryString.parse(location.search);

        // If we have been logged out correctly
        // We get the profile to check the token is valid
        if(prevProps.logged_out === false && logged_out === true) {
            getProfile({token: uriObj.t});
        }

        // If we are logged out
        // And there are errors
        // Redirect to login
        // (e.g. token not valid, or attempt of force entry)
        else if(
            logged_out === true
            && (
                !prevProps.errors
                || prevProps.errors.length === 0
            )
            && errors
            && errors.length > 0
        ) {
            this.setState({
                redirectLogin: true,
            });
        }

        // If we are logged out
        // And the user comes back OK,
        // It means the token is legitimate
        // We replace the token in the store
        // And redirect to dashboard
        else if(
            logged_out === true
            && !prevProps.user
            && user
        ) {
            const data = {
                token: uriObj.t,
            };
            replaceToken({ data });

            this.setState({
                redirectDashboard: true,
            });
        }
    }

    render() {
        const { redirectLogin, redirectDashboard } = this.state;

        if (redirectLogin === true) {
            return <Redirect to="/login" />;
        }

        if (redirectDashboard === true) {
            return <Redirect to="/dashboard" />;
        }

        return <SpinnerRow />;
    }
}

const mapStateToProps = (state) => {
    const {
        error,
        logged_out,
        token,
        user,
    } = state.auth;
    
    const errors = getApiErrorMessages(error, false);

    return {
        errors,
        user,
        logged_in: token ? true : false,
        logged_out: logged_out === true
    };
};

const mapDispatchToProps = {
    getProfile,
    logout,
    replaceToken,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Auth);
