import * as viewingLengthsAPI from '../index';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import {useQuery} from '@tanstack/react-query';

const allViewingLengthsQueryKey = (token, queryParams) => ['viewing-lengths', 'all', token, queryParams];

export const useViewingLengths = (token, params) => {
  const queryParams = parseQueryParams(params);
  const reactQueryOptions = parseReactQueryOptions(params);
  const queryKey = allViewingLengthsQueryKey(token, queryParams);

  const {
    data,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(
    queryKey,
    () => new Promise((resolve, reject) => {
      viewingLengthsAPI.get({...queryParams, token})
        .then(res => resolve(res.data.resources))
        .catch(reject);
    }),
    reactQueryOptions,
  );
  
  return {
    viewingLengths: data,
    isLoading,
    isError,
    error,
    refetch
  }
}

const parseQueryParams = params => {
  if (isObject(get(params, 'queryParams'))) {
    return params.queryParams;
  }
  return {};
}

const parseReactQueryOptions = params => {
  if (isObject(get(params, 'reactQueryOptions'))) {
    return params.reactQueryOptions;
  }
  return {
    staleTime: 5 * 60 * 1000
  };
}