export const getOAuthUrl = async (params) => {
    let url = process.env.REACT_APP_API_BASE_URL + '/api/v1/oauth/reapit/url';

    if (params && params.mode && ['sign_in', 'connect'].indexOf(params.mode) > -1) {
        url += `?mode=${params.mode}`
    }

    return await fetch(url, {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }),
    }).then(response => 
        typeof response === 'object' && response instanceof Response 
            ? response.json() 
            : response
    );
}