import isNil from 'lodash/isNil';
import apiRequest from '../apiRequest';

export const get = async ({ token }) =>
    apiRequest('sms-types', {
        token
    });

export const paginate = async ({token, page, pageSize, q}) =>
    apiRequest('sms-types/paginate', {
        token,
        params: {
            page,
            page_size: pageSize,
            q: !isNil(q) ? q : undefined
        }
    });

export const find = ({ id, token }) =>
    apiRequest(`sms/${id}`, {
        method: 'GET',
        token
    });

export const create = ({ token, ...rest }) =>
    apiRequest('sms-types', {
        method: 'POST',
        token,
        data: rest
    });

export const toggle = ({ id, token }) =>
    apiRequest(`client-type/${id}`, {
        method: 'GET',
        token
    });

export const update = ({ id, token, ...rest }) =>
    apiRequest(`sms/${id}`, {
        method: 'POST',
        token,
        data: rest
    });

export const destroy = ({ id, token }) =>
    apiRequest(`sms-types/${id}`, {
        method: 'DELETE',
        token
    });