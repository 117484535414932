import { isAxiosError } from '../api/axios';
import { call, put } from 'redux-saga/effects';

export function* sagaApiRequest({
    request,          // [Function] The function that will make the API request.
    args: _args,      // [any, any[]] The arguments to pass to the API request function.
    onRequestSuccess, // [Function] A function that will run when the API request is successfull.
    onSuccess,        // [String] The action type to dispatch on success.
    onError           // [String] The action type to dispatch on error.
}) {
    try {
        // Ensure the args are in an array format.
        const args = Array.isArray(_args) ? _args : [_args];

        // Make the request.
        const response = yield call(request, ...args);

        // On request success.
        if (onRequestSuccess) {
            yield call(onRequestSuccess, response);
        }

        // Yield the success action.
        yield put({
            type: onSuccess,
            payload: response.data
        });
    } catch(e) {
        yield put({
            type: onError,
            error: getErrorMessageOrMessagesFromError(e)
        });
    }
}

/**
 * Get the message or errors from the error.
 * 
 * The clients web app currently has a strange method for displaying errors.
 * It will take whatever object it is passed, which could be a string, array,
 * or object. And it recursively extracts all the strings from the item it
 * is passed. Thats why here, we look for 'errors', then 'error', then 'message'
 * and return whichever of those we find, that way we know reasonable error
 * messages will be displayed. With a fallback to 'Internal server error'.
 * 
 * @param {Error} e 
 * @returns {String|String[]}
 */
export const getErrorMessageOrMessagesFromError = e => {
    let message = 'Internal server error';

    if (isAxiosError(e) && e.response && e.response.data) {
        if (e.response.data.error) {
            message = e.response.data.error;
        } else if (e.response.data.errors) {
            message = e.response.data.errors;
        } else if (e.response.data.message) {
            message = e.response.data.message;
        }
    }

    return message;
}