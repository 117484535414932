import axios from '../axios';
import apiRequest from '../apiRequest';

export const login = data =>
    apiRequest('auth/login', {
        method: 'POST',
        data
    });

export const loginAs = data =>
    apiRequest('auth/login-as', {
        method: 'POST',
        data
    });

export const signInWithReapit = ({ code }) =>
    apiRequest(`oauth/reapit/sign-in?code=${code}`, {
        method: 'POST'
    });


export const passwordReset = data =>
    apiRequest('auth/password/reset', {
        method: 'POST',
        data
    });

export const passwordResetEmail = data =>
    apiRequest('auth/password/email', {
        method: 'POST',
        data
    });

export const register = data =>
    apiRequest('auth/register', {
        method: 'POST',
        data
    });

export const logout = ({ token, ...rest }) =>
    apiRequest('auth/logout', {
        method: 'POST',
        token,
        data: rest
    });

export const getProfile = ({ token, ...rest }) =>
    apiRequest('auth/me?expand[]=client.reapit_details&expand[]=oauth_connections&expand[]=client.client_type.settings', {
        method: 'GET',
        token,
        data: rest
    });

export const updateProfile = ({ token, ...rest }) =>
    apiRequest('auth/me', {
        method: 'PUT',
        token,
        data: rest
    });

export const readSuzy = ({ token, ...rest }) =>
    apiRequest('auth/me', {
        method: 'PUT',
        token,
        data: rest
    });

export const twoFactorCodeVerify = ({ token, ...rest }) =>
    apiRequest('auth/2fa/verify', {
        method: 'POST',
        data: {...rest, token}
    });

export const twoFactorCodeResend = ({ token, ...rest }) =>
    apiRequest('auth/2fa/resend', {
        method: 'POST',
        token,
        data: rest
    });

export const uploadProfilePicture = async data => {
    const formData = new FormData();
    
    Object.entries(data).forEach(([name, value]) => {
        formData.append(name, value);
    });

    return axios.post(
        process.env.REACT_APP_FILE_UPLOAD_HANDLER,
        formData
    );
};
