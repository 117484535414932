import actions from './actions';

const initial_state = {
    error: null,
    fetching_resources: false,
    resource: null,
    total: 0,
};

const reducer = (state = initial_state, action) => {
    switch(action.type) {
        case actions.CLEAR_METADATA_RESOURCES:
            return {
                ...state,
                fetching_resources: false,
                error: null,
                resource: null,
            };
        case actions.SEARCH_POSTCODE_REQUEST: {
            return {
                ...state,
                error: null,
                fetching_resources: true,
                resource: null,
                total: 0,
            };
        }
        case actions.SEARCH_POSTCODE_SUCCESS: {
            return {
                ...state,
                error: null,
                fetching_resources: false,
                resource: action.payload,
            };
        }
        case actions.SEARCH_POSTCODE_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_resources: false,
            };
		default:
        	return state;
	}
};

export default reducer;
