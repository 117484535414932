import createActionName from '../createActionName';
import { reducerName } from './variables';

export const CHANGE_PAGE_RESOURCES = createActionName(reducerName, 'CHANGE_PAGE_RESOURCES');
export const CLEAR_METADATA_RESOURCES = createActionName(reducerName, 'CLEAR_METADATA_RESOURCES');
export const CLEAR_METADATA_RESOURCE_CREATE = createActionName(reducerName, 'CLEAR_METADATA_RESOURCE_CREATE');
export const CREATE_RESOURCE_REQUEST = createActionName(reducerName, 'CREATE_RESOURCE_REQUEST');
export const CREATE_RESOURCE_SUCCESS = createActionName(reducerName, 'CREATE_RESOURCE_SUCCESS');
export const CREATE_RESOURCE_ERROR = createActionName(reducerName, 'CREATE_RESOURCE_ERROR');

// actions
const actions = {
  	CHANGE_PAGE_RESOURCES,
  	CLEAR_METADATA_RESOURCES,
    CLEAR_METADATA_RESOURCE_CREATE,
    CREATE_RESOURCE_REQUEST,
	CREATE_RESOURCE_SUCCESS,
	CREATE_RESOURCE_ERROR,
};

// action creators
export const changePageResources = payload => ({
    type: actions.CHANGE_PAGE_RESOURCES,
    payload,
});

export const clearMetadataResources = payload => ({
    type: actions.CLEAR_METADATA_RESOURCES,
    payload,
});

export const clearMetadataResourceCreate = payload => ({
    type: actions.CLEAR_METADATA_RESOURCE_CREATE,
});

export const createResource = payload => ({
    type: actions.CREATE_RESOURCE_REQUEST,
    payload,
});

export default actions;
