import moment from 'moment';

export const momentSqlFormat = 'YYYY-MM-DD HH:mm:ss';
export const momentSqlTFormat = 'YYYY-MM-DDTHH:mm:ss';
export const momentSqlDateFormat = 'YYYY-MM-DD';
export const momentDateFormat = 'dddd DD MMMM YYYY';
export const momentTimeFormat = 'HH:mma';
export const momentTimeSQLFormat = 'HH:mm';
export const momentDateTimeFormat = 'dddd DD MMMM YYYY HH:mma';
export const momentSqlTimeFormat = 'HH:mm:ss';

export const getFullDateText = dateTimeText => {
    return dateTimeText
        ? moment(dateTimeText, momentSqlFormat).format(momentDateFormat)
        : null
};

export const getTimeText = dateTimeText => {
    return dateTimeText
        ? moment(dateTimeText, momentSqlFormat).format(momentTimeFormat)
        : null
};

export const getTimeOnly = (timeText, twelveHourFormat = true) => {

    return timeText
        ? twelveHourFormat 
            ? moment(timeText, momentSqlTimeFormat).format(momentTimeFormat) 
            : moment(timeText, momentSqlTimeFormat).format(momentTimeSQLFormat) 
        : null
};

export const getOccurrencesFromRecurringDateTimeResources = resources => {
    const allOccurrences = resources.reduce(
        (result, resource) => {
            const occurrences = resource.dates.map((occurrence, idx) => {
                return {
                    name: resource.name,
                    start_date: moment(occurrence.start_date, momentSqlFormat).format(momentSqlTFormat),
                    end_date: moment(occurrence.end_date, momentSqlFormat).format(momentSqlTFormat),
                    id: resource.id,
                };
            });

            return [
                ...result,
                ...occurrences
            ];
        },
        []
    );

    return allOccurrences;
};

export const formatDateTimeResources = resources => {
    const newResources = resources.reduce(
        (result, resource) => {
            return [
                ...result,
                {
                    name: resource.name,
                    start_date: moment(resource.start_date, momentSqlFormat).format(momentSqlTFormat),
                    end_date: moment(resource.end_date, momentSqlFormat).format(momentSqlTFormat),
                    id: resource.id,
                }
            ];
        },
        []
    );

    return newResources;
};

export const formatDateTimeAppointments = resources => {
    const newResources = resources.reduce(
        (result, resource) => {
            const length_minutes = resource.main_viewing_length
                ? resource.main_viewing_length.duration_minutes
                : 60;
            return [
                ...result,
                {
                    name: (resource && resource.property ? resource.property.postcode+' - ' : '')+'Appointment',
                    start_date: (resource ? moment(resource.preferred_date_1, momentSqlFormat) : ''),
                    end_date: moment(resource.preferred_date_1, momentSqlFormat).add(length_minutes, 'm').format(momentSqlTFormat),
                    id: resource.id,
                    viewing_type: resource.main_viewing_length?.viewing_type?.name,
                    status: resource.status_text,
                    address: resource.property?.full_address
                }
            ];
        },
        []
    );

    return newResources;
}
