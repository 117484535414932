import apiRequest from '../apiRequest';

export const getNotifications = (token, options) =>
    apiRequest(`/notifications`, {
        token,
        ...options
    });

export const getLatestNotifications = (token, limit = 3) =>
    apiRequest(`/notifications`, {
        token,
        params: { limit }
    });

export const getUnreadNotificationCounts = token =>
    apiRequest(`/notifications/unread-counts`, {
        token
    });

export const readNotification = (token, notificationId) =>
    apiRequest(`/notifications/read-website`, {
        method: 'POST',
        token,
        data: {id: notificationId}
    });