import PropTypes from 'prop-types';
import toastNotification from './toastNotification';

const feedbackQuestionCreateSuccessNotification = ({ resourceDisplayName, iconClassName }) => toastNotification({
    iconClassName: iconClassName ? iconClassName : 'fa-warning',
    message: 'Please note, this question will only begin to appear in Viewber feedback after admin approval.',
    type: 'warning',
});

feedbackQuestionCreateSuccessNotification.propTypes = {
    resourceDisplayName: PropTypes.string
};

export default feedbackQuestionCreateSuccessNotification;
