import apiRequest from '../apiRequest';

export const get = async ({ token, params, data }) =>
    apiRequest('client-types', {
        token,
        params,
        data
    });


