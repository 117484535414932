import React from 'react';

const FormDivider = ({label, children}) => (
    <div>
        {label && <h2>{label}</h2>}
        {children}
        <hr />
    </div>
)

export default FormDivider;