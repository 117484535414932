import apiRequest from '../apiRequest';

export const get = async ({ token, params, data }) =>
    apiRequest('feedback-questions', {
        token,
        params,
        data
    });

export const find = ({ id, token }) =>
    apiRequest(`feedback-questions/${id}`, {
        method: 'GET',
        token
    });

export const create = ({ token, ...rest }) =>
    apiRequest('feedback-questions', {
        method: 'POST',
        token,
        data: rest
    });

export const update = ({ id, token, ...rest }) =>
    apiRequest(`feedback-questions/${id}`, {
        method: 'PUT',
        token,
        data: rest
    });

export const destroy = ({ id, token }) =>
    apiRequest(`feedback-questions/${id}`, {
        method: 'DELETE',
        token
    });