import isNil from 'lodash/isNil';
import apiRequest from '../apiRequest';

export const get = async ({ token, params, data }) =>
    apiRequest('viewing-packs-available', {
        token,
        params,
        data
    });

export const paginate = async ({token, page, pageSize, q}) =>
    apiRequest('viewing-packs-available/paginate', {
        token,
        params: {
            page,
            page_size: pageSize,
            q: !isNil(q) ? q : undefined
        }
    });

export const find = ({ id, token }) =>
    apiRequest(`viewing-packs-available/${id}`, {
        method: 'GET',
        token
    });

export const create = ({ token, ...rest }) =>
    apiRequest('viewing-packs-available', {
        method: 'POST',
        token,
        data: rest
    });

    export const update = ({ id, token, ...rest }) =>
    apiRequest(`viewing-packs-available/${id}`, {
        method: 'PUT',
        token,
        data: rest
    });

export const destroy = ({ id, token }) =>
    apiRequest(`viewing-packs-available/${id}`, {
        method: 'DELETE',
        token
    });