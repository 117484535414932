import { all, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { sagaApiRequest } from '../helpers';
import * as appointmentsAPI from '../../api/appointments';

export function* getFeedbackKeyHolding() {
    yield takeEvery(actions.GET_FEEDBACK_KEY_HOLDING_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.getFeedbackKeyHolding,
            args: payload.data,
            onSuccess: actions.GET_FEEDBACK_KEY_HOLDING_SUCCESS,
            onError: actions.GET_FEEDBACK_KEY_HOLDING_ERROR
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(getFeedbackKeyHolding),
    ]);
}
