import PropTypes from 'prop-types';
import toastNotification from './toastNotification';

export const smsMessageUpdateSuccessNotification = ({ resourceDisplayName }) => toastNotification({
    iconClassName: 'fa-check',
    message: (resourceDisplayName ? resourceDisplayName : "Resource")
        +' updated successfully.  Please note, the default message will be in use until this message is approved by Admin.',
    type: 'success',
});

smsMessageUpdateSuccessNotification.propTypes = {
    resourceDisplayName: PropTypes.string
};

export default smsMessageUpdateSuccessNotification;
