import React from 'react';
import FormGroup from '../Form/FormGroup';
import FormikTextInput from './FormikTextInput';

const FormikFormGroupTextInput = props => {
    return (
        <FormGroup>
            <FormikTextInput {...props} />
        </FormGroup>
    )
}

FormikFormGroupTextInput.propTypes = FormikTextInput.propTypes;

export default FormikFormGroupTextInput;