import actions     from './actions';
import baseReducer from '../base/reducer';

const initial_state = {
    collected: false,
    notes:     '',
    error:     null,
    fetching:  false,
    show:      false,
};

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case actions.GET_FEEDBACK_KEY_HOLDING_REQUEST:
            return {
                ...state,
                error:    null,
                fetching: true,
                show:     false,
            };
        case actions.GET_FEEDBACK_KEY_HOLDING_SUCCESS: {
            const { resource } = action.payload;
            
            return {
                ...state,
                ...resource,
                error:    null,
                fetching: false,
                show:     true,
            };
        }
        case actions.GET_FEEDBACK_KEY_HOLDING_ERROR:
            return {
                ...state,
                error:    action.payload,
                fetching: false,
                show:     false,
            };
        default:
            return baseReducer(actions)(state, action);
    }
};

export default reducer;
