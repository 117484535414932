import actions from './actions';
import baseReducer from '../base/reducer';

const initial_state = {
    created: false,
    date_filtered_resources: [],
    destroyed: false,
    current_page: 1,
    error: null,
    fetching_resources: false,
    merged_viewings: null,
    resource: null,
    resources: [],
    paginated_resources: {
        1: []
    },
    order: null,
    total: 0,
    updated: false,
    validated: false,
};

const reducer = (state = initial_state, action) => {
    switch(action.type) {
        case actions.CHANGE_PAGE_RESOURCES:
            return {
                ...state,
                current_page: action.payload.data.page
            };
        case actions.CLEAR_METADATA_RESOURCES: {
            const { data } = action.payload;

            return {
                ...state,
                current_page: 1,
                destroyed: false,
                error: null,
                fetching_resources: false,
                resources: [],
                paginated_resources: (
                    data.query !== ''
                    && data.query !== null
                    && typeof data.query !== 'undefined'
                )
                    // If I've searched before,
                    // Clear the resources so on next reload
                    // A re-fetch will be needed
                    ? {
                        1: []
                    }
                    : {
                        ...state.paginated_resources
                    }
            };
        }
        case actions.CLEAR_METADATA_RESOURCE_EDIT: {
            return {
                ...state,
                created: false,
                destroyed: false,
                error: null,
                resource: null,
                updated: false,
            };
        }
        // DO NOT MIGRATE CLEAR_METADATA_RESOURCE_CREATE TO BASE REDUCER AS IT's DIFFERENT
        case actions.CLEAR_METADATA_RESOURCE_CREATE:
            return {
                ...state,
                created: false,
                error: null,
                merged_viewings: null,
                order: null,
                resource: null,
            };
        case actions.CREATE_RESOURCE_REQUEST: {
            return {
                ...state,
                error: null,
                created: false,
                merged_viewings: null,
                order: null,
            };
        }
        // DO NOT MIGRATE CREATE_RESOURCE_SUCCESS TO BASE REDUCER AS IT's DIFFERENT
        case actions.CREATE_RESOURCE_SUCCESS: {
            const {
                merged_viewings,
                order,
                resource,
            } = action.payload;

            return {
                ...state,
                resource,
                created: true,
                error: null,
                merged_viewings: merged_viewings ? merged_viewings : null,
                order: order ? order : null
            };
        }
        case actions.CREATE_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
                created: false,
                merged_viewings: null,
            };
        case actions.DESTROY_RESOURCE_REQUEST:
            return {
                ...state,
                error: null,
                destroyed: false
            };
        case actions.DESTROY_RESOURCE_SUCCESS:
            return {
                ...state,
                destroyed: true,
                error: null,
            };
        case actions.DESTROY_RESOURCE_ERROR:
            return {
                ...state,
                destroyed: false,
                error: action.error,
            };
        case actions.GET_PAGINATED_RESOURCES_REQUEST: {
            const { data } = action.payload;

            return {
                ...state,
                current_page: data.page,
                error: null,
                fetching_resources: true,
                paginated_resources: {
                    ...state.paginated_resources,
                    [data.page]: [],
                },
                total: 0,
            };
        }
        case actions.GET_PAGINATED_RESOURCES_SUCCESS: {
            const { data, meta } = action.payload;
            
            return {
                ...state,
                current_page: meta.current_page,
                total: meta.total,
                error: null,
                fetching_resources: false,
                paginated_resources: {
                    ...state.paginated_resources,
                    [meta.current_page]: data,
                },
            };
        }
        case actions.GET_PAGINATED_RESOURCES_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_resources: false,
                total: 0
            };
        case actions.UPDATE_RESOURCE_REQUEST:
            return {
                ...state,
                updated: false,
                error: null,
                merged_viewings: null,
            };
        case actions.UPDATE_RESOURCE_SUCCESS: {
            const {
                merged_viewings,
                order,
                resource,
            } = action.payload;

            return {
                ...state,
                resource,
                updated: true,
                error: null,
                merged_viewings: merged_viewings ? merged_viewings : null,
                order: order ? order : null
            };
        }
        case actions.UPDATE_RESOURCE_ERROR:
            return {
                ...state,
                updated: false,
                error: action.error,
            };
        case actions.GET_DATE_FILTERED_RESOURCES_REQUEST: {
            return {
                ...state,
                error: null,
                fetching_resources: true,
                date_filtered_resources: [],
            };
        }
        case actions.GET_DATE_FILTERED_RESOURCES_SUCCESS: {
            const { resources } = action.payload;

            return {
                ...state,
                error: null,
                fetching_resources: false,
                date_filtered_resources: resources,
            };
        }
        case actions.GET_DATE_FILTERED_RESOURCES_ERROR:
            return {
                ...state,
                fetching_resources: false,
                error: action.error,
            };
        case actions.FIND_CONFIRMED_APPOINTMENT_RESOURCE_REQUEST:
            return {
                ...state,
                error: null,
            };
        case actions.FIND_CONFIRMED_APPOINTMENT_RESOURCE_SUCCESS:
            return {
                ...state,
                error: null,
                resource: action.payload.resource,
            };
        case actions.FIND_CONFIRMED_APPOINTMENT_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case actions.CANCEL_RESOURCE_REQUEST:
            return {
                ...state,
                created: false,
                error: null,
                updated: false,
            };
        case actions.CANCEL_RESOURCE_SUCCESS: {
            const { resource } = action.payload;

            return {
                ...state,
                resource,
                error: null,
                updated: true,
            };
        }
        case actions.CANCEL_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
                updated: false,
            };
        case actions.VALIDATE_RESOURCE_REQUEST: {
            return {
                ...state,
                error: null,
                validated: false,
            };
        }
        case actions.VALIDATE_RESOURCE_SUCCESS: {
            return {
                ...state,
                error: null,
                validated: true,
            };
        }
        case actions.VALIDATE_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
                validated: false,
            };
        case actions.FIND_CONFIRMED_APPOINTMENT_OVERRUN_RESOURCE_REQUEST:
            return {
                ...state,
                error: null,
            };
        case actions.FIND_CONFIRMED_APPOINTMENT_OVERRUN_RESOURCE_SUCCESS:
            return {
                ...state,
                error: null,
                resource: action.payload.resource,
            };
        case actions.FIND_CONFIRMED_APPOINTMENT_OVERRUN_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case actions.UPDATE_CONFIRMED_APPOINTMENT_OVERRUN_RESOURCE_REQUEST:
            return {
                ...state,
                error: null,
            };
        case actions.UPDATE_CONFIRMED_APPOINTMENT_OVERRUN_RESOURCE_SUCCESS:
            return {
                ...state,
                error: null,
                resource: action.payload.resource,
            };
        case actions.UPDATE_CONFIRMED_APPOINTMENT_OVERRUN_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
            };
        default:
            return baseReducer(actions)(state, action);
    }

};

export default reducer;