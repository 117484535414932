import { all, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { sagaApiRequest } from '../helpers';
import * as viewingsAPI from '../../api/viewings';

export function* createResourceRequest() {
    yield takeEvery(actions.CREATE_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: viewingsAPI.create,
            args: payload.data,
            onSuccess: actions.CREATE_RESOURCE_SUCCESS,
            onError: actions.CREATE_RESOURCE_ERROR
        });
    });
}

export function* destroyResourceRequest() {
    yield takeEvery(actions.DESTROY_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: viewingsAPI.destroy,
            args: payload.data,
            onSuccess: actions.DESTROY_RESOURCE_SUCCESS,
            onError: actions.DESTROY_RESOURCE_ERROR
        });
    });
}

export function* findResourceRequest() {
    yield takeEvery(actions.FIND_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: viewingsAPI.find,
            args: payload.data,
            onSuccess: actions.FIND_RESOURCE_SUCCESS,
            onError: actions.FIND_RESOURCE_ERROR
        });
    });
}

export function* getPaginatedResourcesRequest() {
    yield takeEvery(actions.GET_PAGINATED_RESOURCES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: viewingsAPI.paginate,
            args: payload.data,
            onSuccess: actions.GET_PAGINATED_RESOURCES_SUCCESS,
            onError: actions.GET_PAGINATED_RESOURCES_ERROR
        });
    });
}

export function* updateResourceRequest() {
    yield takeEvery(actions.UPDATE_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: viewingsAPI.update,
            args: payload.data,
            onSuccess: actions.UPDATE_RESOURCE_SUCCESS,
            onError: actions.UPDATE_RESOURCE_ERROR
        });
    });
}

export function* getDateFilteredResourcesRequest() {
    yield takeEvery(actions.GET_DATE_FILTERED_RESOURCES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: viewingsAPI.dateFilter,
            args: payload.data,
            onSuccess: actions.GET_DATE_FILTERED_RESOURCES_SUCCESS,
            onError: actions.GET_DATE_FILTERED_RESOURCES_ERROR
        });
    });
}

export function* findConfirmedAppointmentResourceRequest() {
    yield takeEvery(actions.FIND_CONFIRMED_APPOINTMENT_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: viewingsAPI.confirmedAppointment,
            args: payload.data,
            onSuccess: actions.FIND_CONFIRMED_APPOINTMENT_RESOURCE_SUCCESS,
            onError: actions.FIND_CONFIRMED_APPOINTMENT_RESOURCE_ERROR
        });
    });
}

export function* cancelResourceRequest() {
    yield takeEvery(actions.CANCEL_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: viewingsAPI.cancel,
            args: payload.data,
            onSuccess: actions.CANCEL_RESOURCE_SUCCESS,
            onError: actions.CANCEL_RESOURCE_ERROR
        });
    });
}

export function* validateResourceRequest() {
    yield takeEvery(actions.VALIDATE_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: viewingsAPI.validate,
            args: payload.data,
            onSuccess: actions.VALIDATE_RESOURCE_SUCCESS,
            onError: actions.VALIDATE_RESOURCE_ERROR
        });
    });
}

export function* findConfirmedAppointmentOverrunResourceRequest() {
    yield takeEvery(actions.FIND_CONFIRMED_APPOINTMENT_OVERRUN_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: viewingsAPI.confirmedAppointmentOverrun,
            args: payload.data,
            onSuccess: actions.FIND_CONFIRMED_APPOINTMENT_OVERRUN_RESOURCE_SUCCESS,
            onError: actions.FIND_CONFIRMED_APPOINTMENT_OVERRUN_RESOURCE_ERROR
        });
    });
}

export function* updateConfirmedAppointmentOverrunResourceRequest() {
    yield takeEvery(actions.UPDATE_CONFIRMED_APPOINTMENT_OVERRUN_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: viewingsAPI.updateConfirmedAppointmentOverrun,
            args: payload.data,
            onSuccess: actions.UPDATE_CONFIRMED_APPOINTMENT_OVERRUN_RESOURCE_SUCCESS,
            onError: actions.UPDATE_CONFIRMED_APPOINTMENT_OVERRUN_RESOURCE_ERROR
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(createResourceRequest),
        fork(destroyResourceRequest),
        fork(findResourceRequest),
        fork(getPaginatedResourcesRequest),
        fork(updateResourceRequest),
        fork(getDateFilteredResourcesRequest),
        fork(findConfirmedAppointmentResourceRequest),
        fork(cancelResourceRequest),
        fork(validateResourceRequest),
        fork(findConfirmedAppointmentOverrunResourceRequest),
        fork(updateConfirmedAppointmentOverrunResourceRequest),
    ]);
}
