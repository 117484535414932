import toastNotification from './toastNotification';

const apiResourceUpdateFailureNotification = ({resourceName, helpText}) => toastNotification({
    type: 'danger',
    message: resourceName
    ? `Failed to update the ${resourceName}.`
    : 'Failed to update the resource',
    iconClassName: 'fa-warning',
    helpText
});

export default apiResourceUpdateFailureNotification;
