import createActionName from '../createActionName';
export const reducerName = 'appointments';

export const CHANGE_PAGE_RESOURCES = createActionName(reducerName, 'CHANGE_PAGE_RESOURCES');
export const CLEAR_METADATA_RESOURCES = createActionName(reducerName, 'CLEAR_METADATA_RESOURCES');
export const CLEAR_METADATA_RESOURCES_EDIT = createActionName(reducerName, 'CLEAR_METADATA_RESOURCES_EDIT');
export const FIND_RESOURCE_REQUEST = createActionName(reducerName, 'FIND_RESOURCE_REQUEST');
export const FIND_RESOURCE_SUCCESS = createActionName(reducerName, 'FIND_RESOURCE_SUCCESS');
export const FIND_RESOURCE_ERROR = createActionName(reducerName, 'FIND_RESOURCE_ERROR');
export const GET_PACK_REQUEST = createActionName(reducerName, 'GET_PACK_REQUEST');
export const GET_PACK_SUCCESS = createActionName(reducerName, 'GET_PACK_SUCCESS');
export const GET_PACK_ERROR = createActionName(reducerName, 'GET_PACK_ERROR');
export const GET_PAGINATED_RESOURCES_REQUEST = createActionName(reducerName, 'GET_PAGINATED_RESOURCES_REQUEST');
export const GET_PAGINATED_RESOURCES_SUCCESS = createActionName(reducerName, 'GET_PAGINATED_RESOURCES_SUCCESS');
export const GET_PAGINATED_RESOURCES_ERROR = createActionName(reducerName, 'GET_PAGINATED_RESOURCES_ERROR');
export const RATE_RESOURCE_REQUEST = createActionName(reducerName, 'RATE_RESOURCE_REQUEST');
export const RATE_RESOURCE_SUCCESS = createActionName(reducerName, 'RATE_RESOURCE_SUCCESS');
export const RATE_RESOURCE_ERROR = createActionName(reducerName, 'RATE_RESOURCE_ERROR');

// actions
const actions = {
	CHANGE_PAGE_RESOURCES,
	CLEAR_METADATA_RESOURCES,
	CLEAR_METADATA_RESOURCES_EDIT,
	FIND_RESOURCE_REQUEST,
	FIND_RESOURCE_SUCCESS,
	FIND_RESOURCE_ERROR,
  	GET_PACK_REQUEST,
  	GET_PACK_SUCCESS,
  	GET_PACK_ERROR,
	GET_PAGINATED_RESOURCES_REQUEST,
	GET_PAGINATED_RESOURCES_SUCCESS,
	GET_PAGINATED_RESOURCES_ERROR,
	RATE_RESOURCE_REQUEST,
	RATE_RESOURCE_SUCCESS,
	RATE_RESOURCE_ERROR,
};

// action creators
export const changePageResources = payload => ({
    type: actions.CHANGE_PAGE_RESOURCES,
	payload,
});

export const clearMetadataResources = payload => ({
    type: actions.CLEAR_METADATA_RESOURCES,
    payload,
});

export const clearMetadataResourceEdit = payload => ({
    type: actions.CLEAR_METADATA_RESOURCES_EDIT,
    payload,
});

export const findResource = payload => ({
    type: actions.FIND_RESOURCE_REQUEST,
    payload,
});

export const getPack = payload => ({
    type: actions.GET_PACK_REQUEST,
    payload,
});

export const getPaginatedResources = payload => ({
    type: actions.GET_PAGINATED_RESOURCES_REQUEST,
    payload,
});

export const rateResource = payload => ({
    type: actions.RATE_RESOURCE_REQUEST,
    payload,
});

export default actions;
