import * as viewingsAPI from '../index';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query'

const showViewingQueryKey = (viewingId, token, queryParams) => ['viewings', viewingId, 'show', token, queryParams];

export const useViewing = (
    viewingId,
    token,
    options
) => {
  const queryClient = useQueryClient();
  const queryParams = parseQueryParams(options);
  const queryKey = showViewingQueryKey(viewingId, token, queryParams);
  const reactQueryOptions = parseReactQueryOptions(options);

  const {
    data,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(
    queryKey,
    () => new Promise((resolve, reject) => {
      viewingsAPI.find({
        id: viewingId,
        token,
        params: queryParams
      })
        .then(res => resolve(res.data.resource))
        .catch(reject);
    }),
    reactQueryOptions
  );

  const {
    mutate: update,
    isLoading: isUpdating,
    isError: updateIsError,
    error: updateError
  } = useMutation(
    ({id, data}) => new Promise((resolve, reject) => {
      viewingsAPI
        .update({ id, token, ...data })
        .then(response => resolve({
          viewing: response.data.resource,
          order: response.data.order
        }))
        .catch(reject);
    }),
    {
      onSuccess: ({viewing, order}) => {
        // Update the cached viewing.
        queryClient.setQueryData(queryKey, viewing);
      }
    }
  );

  const {
    mutate: cancel,
    isLoading: isCancelling,
    isError: cancelIsError,
    error: cancelError
  } = useMutation(
    ({id, data}) => new Promise((resolve, reject) => {
      viewingsAPI
        .cancel({ id, token, ...data })
        .then(response => resolve(response.data.resource))
        .catch(reject);
    }),
    {
      onSuccess: viewing => {
        // Update the cached viewing.
        queryClient.setQueryData(queryKey, viewing);
      }
    }
  );

  return {
    viewing: data,
    isLoading,
    isError,
    error,
    refetch,
    actions: {
      update: {
        handle: (id, data, mutationOptions) => update({id, token, data}, mutationOptions),
        isLoading: isUpdating,
        isError: updateIsError,
        error: updateError
      },
      cancel: {
        handle: (id, data, mutationOptions) => cancel({id, token, data}, mutationOptions),
        isLoading: isCancelling,
        isError: cancelIsError,
        error: cancelError
      }
    }
  }
}

const parseQueryParams = params => {
  if (isObject(get(params, 'queryParams'))) {
    return params.queryParams;
  }
  return {};
}

const parseReactQueryOptions = params => {
  if (isObject(get(params, 'reactQueryOptions'))) {
    return params.reactQueryOptions;
  }
  return {
    staleTime: 5 * 60 * 1000
  };
}