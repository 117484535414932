import createActionName from '../createActionName';
import { reducerName } from './variables';

export const GET_POSTCODE_CLOSEST_KEYNEST_STORES_REQUEST = createActionName(reducerName, 'GET_POSTCODE_CLOSEST_KEYNEST_STORES_REQUEST');
export const GET_POSTCODE_CLOSEST_KEYNEST_STORES_SUCCESS = createActionName(reducerName, 'GET_POSTCODE_CLOSEST_KEYNEST_STORES_SUCCESS');
export const GET_POSTCODE_CLOSEST_KEYNEST_STORES_ERROR = createActionName(reducerName, 'GET_POSTCODE_CLOSEST_KEYNEST_STORES_ERROR');
export const GET_PROPERTY_CLOSEST_KEYNEST_STORES_REQUEST = createActionName(reducerName, 'GET_PROPERTY_CLOSEST_KEYNEST_STORES_REQUEST');
export const GET_PROPERTY_CLOSEST_KEYNEST_STORES_SUCCESS = createActionName(reducerName, 'GET_PROPERTY_CLOSEST_KEYNEST_STORES_SUCCESS');
export const GET_PROPERTY_CLOSEST_KEYNEST_STORES_ERROR = createActionName(reducerName, 'GET_PROPERTY_CLOSEST_KEYNEST_STORES_ERROR');

// actions
const actions = {
    GET_POSTCODE_CLOSEST_KEYNEST_STORES_REQUEST,
    GET_POSTCODE_CLOSEST_KEYNEST_STORES_SUCCESS,
    GET_POSTCODE_CLOSEST_KEYNEST_STORES_ERROR,
    GET_PROPERTY_CLOSEST_KEYNEST_STORES_REQUEST,
    GET_PROPERTY_CLOSEST_KEYNEST_STORES_SUCCESS,
    GET_PROPERTY_CLOSEST_KEYNEST_STORES_ERROR,
};

// action creators
export const getPropertyClosestKeynestStores = payload => ({
    type: actions.GET_PROPERTY_CLOSEST_KEYNEST_STORES_REQUEST,
    payload,
});

export const getPostcodeClosestKeynestStores = payload => ({
    type: actions.GET_POSTCODE_CLOSEST_KEYNEST_STORES_REQUEST,
    payload,
});

export default actions;
