import isNil from 'lodash/isNil';
import apiRequest from '../apiRequest';

export const paginate = async ({
	token,
	page,
	pageSize,
	q,
	sort_name,
	sort_direction,
	filters
}) => apiRequest('recurring-viewings/paginate', {
	token,
	params: {
		page,
		page_size: pageSize,
		q: !isNil(q) ? q : undefined,
		sort_name,
		sort_direction,
		filters
	}
});

export const paginateRecurrences = async ({
   token,
   id,
   page,
   pageSize,
   q,
   sort_name,
   sort_direction,
   filters
}) => apiRequest(`recurring-viewings/${id}/paginate-recurrences`, {
	token,
	params: {
		page,
		page_size: pageSize,
		q: !isNil(q) ? q : undefined,
		sort_name,
		sort_direction,
		filters
	}
});

export const find = ({ id, token, params }) =>
	apiRequest(`recurring-viewings/${id}`, {
		method: 'GET',
		token,
		params
	});

export const update = ({ id, token, ...rest }) =>
	apiRequest(`recurring-viewings/${id}`, {
		method: 'PUT',
		token,
		data: rest
	});