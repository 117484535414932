import React from 'react';
import PropTypes from 'prop-types';
import { FormText } from './index';
import { Input, Label, FormFeedback } from 'reactstrap';

const Checkbox = ({
    id,
    name,
    value,
    label,
    className,
    checked,
    disabled,
    invalid,
    helpText,
    error,
    onChange
}) => {
    return (
        <React.Fragment>
            <Label check>
                <Input
                    id={id}
                    name={name}
                    type='checkbox'
                    value={value}
                    className={className}
                    checked={checked}
                    disabled={disabled}
                    invalid={invalid}  
                    onChange={onChange}
                />
                {label}
            </Label>
            {helpText && <FormText>{formText}</FormText>}
            {error &&
                (Array.isArray(error)
                    ? error.map((e,i) => <FormFeedback key={i}>{e}</FormFeedback>)
                    : <FormFeedback>{error}</FormFeedback>
                )
            }
        </React.Fragment>
    );
}

Checkbox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    label: PropTypes.string,
    className: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    helpText: PropTypes.string,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    onChange: PropTypes.func
}

export default Checkbox;