import { all, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { sagaApiRequest } from '../helpers';
import * as postcodesAPI from '../../api/postcodes';

export function* searchPostcodeRequest() {
    yield takeEvery(actions.SEARCH_POSTCODE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: postcodesAPI.search,
            args: payload.data,
            onSuccess: actions.SEARCH_POSTCODE_SUCCESS,
            onError: actions.SEARCH_POSTCODE_ERROR
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(searchPostcodeRequest),
    ]);
}
