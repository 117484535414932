import apiRequest from '../apiRequest';

export const closestToPostcode = async ({ token, ...rest }) =>
    apiRequest('keynest-stores/closest', {
        method: 'POST',
        token,
        data: rest
    });

export const closestToProperty = async ({ id, token }) =>
    apiRequest(`properties/${id}/closest-keynest-stores`, {
        method: 'GET',
        token
    });