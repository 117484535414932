import actions from './actions';
import baseReducer from '../base/reducer';

const initial_state = {
    created: false,
    current_page: 1,
    destroyed: false,
    error: null,
    fetching_resources: false,
    resource: null,
    resources: [],
    paginated_resources: {
        1: [],
    },
    total: 0,
    updated: false
};

const reducer = (state = initial_state, action) => {
    switch(action.type) {
        case actions.CHANGE_PAGE_RESOURCES:
            return {
                ...state,
                current_page: action.payload.data.page
            };
        case actions.CLEAR_METADATA_RESOURCES: {
            return {
                ...state,
                created: false,
                current_page: 1,
                destroyed: false,
                error: null,
                fetching_resources: false,
                resource: null,
                resources: [],
                paginated_resources: {
                    1: [],
                },
            };
        }
        case actions.CLEAR_METADATA_RESOURCE_CREATE:
            return {
                ...state,
                error: null,
                // created: false,
                resource: null,
            };
        case actions.CREATE_RESOURCE_REQUEST: {
            return {
                ...state,
                error: null,
                created: false,
            };
        }
        case actions.CREATE_RESOURCE_SUCCESS:
            return {
                ...state,
                error: null,
                created: true,
                resource: action.payload.resource,
            };
        case actions.CREATE_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
                created: false,
            };
        default:
            return baseReducer(actions)(state, action);
    }
};

export default reducer;
